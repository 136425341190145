import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import LockIcon from "@mui/icons-material/Lock";
import axios from "axios";
import { useSelector } from "react-redux";
import { message } from "antd";
import IMAGES from "../../img";
import "./CourseHistory.css";

const CourseHistory = ({ heading, data }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [allCourse, setAllCourse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState(null);

  async function getAllUserCourse() {
    try {
      const res = await axios.post("/api/course/get-user-course", {
        packageName: user?.course,
      });
      if (res.data.success) {
        setAllCourse(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (user !== null) {
      getAllUserCourse();
    }
  }, [user]);

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <div className="tab">
      <div className="welcome-container">
        <div className="username">
          <span>
            <b>{heading}</b>
          </span>
        </div>
      </div>
      <div className="user-course-container">
        {!loading &&
          allCourse?.map((course) => {
            return (
              <div className="user-course" key={course?._id}>
                {!data && (
                  <div className="w-100">
                    <img
                      className="user-course-image"
                      width={"30%"}
                      src={`/courseImages/${course?.image}`}
                      alt=""
                    />
                  </div>
                )}
                <div className="user-course-content">
                  <h5 className="fs-5">
                    {capitalizeFirstLetter(course?.courseName)} Course
                  </h5>
                  <span className="mb-3">Duration: Lifetime</span>
                  <>
                    {/* <div className="d-flex justify-content-between align-items-center">
                        <span className="fw-bold d-flex justify-content-between align-items-center">
                          <span className="fs-4 text-danger">
                            Rs. {course?.price}
                          </span>
                          <span></span>
                        </span>
                        <button
                          disabled={data}
                          className="course-btn bg-danger"
                          onClick={() =>
                            navigate(`/courseInfo/${course?.courseName}`)
                          }
                        >
                          <LockIcon className="fs-5" />{" "}
                          {data ? "Locked" : "Unlock"}{" "}
                        </button>
                      </div> */}
                  </>
                  <div className="d-flex justify-content-between align-items-center">
                    <button
                      className="course-btn"
                      onClick={() =>
                        navigate(
                          `/course/${(course?.courseName).replace(/\s+/g, "-")}`
                        )
                      }
                    >
                      {" "}
                      Watch Now{" "}
                    </button>
                    <span className="me-4 text-primary">
                      Journey to Brilliance
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        {loading && (
          <div className="d-flex align-items-center justify-content-center">
            <span
              className="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="ms-2">Loading...</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CourseHistory;
