import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header/Header";
import { Link, useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { SetUser } from "../redux/features/userSlice";
import "./Profile.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Layout from "./layout/Layout";
import IMAGES from "../img";

// Inside your component or file

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { user } = useSelector((state) => state.user);
  const [photo, setPhoto] = useState(null);
  const [password, setPassword] = useState(null);
  const [alert, setAlert] = useState(null);
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const updateProfileDetails = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    if (photo) {
      formData.append("photo", photo);
    }
    formData.append("id", user?._id);
    formData.append("name", form?.name);
    if (password) {
      formData.append("password", password);
    }
    formData.append("state", form?.state);
    formData.append("city", form?.city);
    formData.append("dob", form?.dob);
    axios({
      method: "POST",
      data: formData,
      withCredentials: true,
      url: `api/user/update-profile-details`,
    })
      .then((res) => {
        if (res.data.success) {
          setTimeout(() => {
            setLoading(false);
            setAlert("Updated Successully");
            dispatch(SetUser(res.data.data));
            setPassword(null);
            setPhoto(null);
          }, 1500);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (user) {
      setForm({
        name: user?.name,
        state: user?.state,
        city: user?.city,
        dob: user?.dob,
      });
      setPassword(null);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 3000);
    }
    // eslint-disable-next-line
  }, [alert]);

  return (
    <Header>
      <Layout>
        <div className="container-fluid profile-container">
          <div className="row">
            <div className="col-12">
              <div className="mb-3">
                {photo ? (
                  <div className="profile-photo " id="user-profile-photo">
                    <img src={URL.createObjectURL(photo)} alt="" />
                  </div>
                ) : (
                  <>
                    {user?.photo ? (
                      <div id="user-profile-photo" className="profile-photo">
                        <img src={`/profileImages/${user?.photo}`} alt="" />
                      </div>
                    ) : (
                      <div
                        id="user-profile-photo"
                        className="profile-photo"
                      ></div>
                    )}
                  </>
                )}
                <input
                  name="photo"
                  type="file"
                  className="form-control mt-3"
                  onChange={(e) => setPhoto(e.target.files[0])}
                  ref={inputRef}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              {/* Name  */}
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Full Name
                </label>
                <input
                  value={form?.name}
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Enter your name"
                  onChange={handleChange}
                />
              </div>

              {/*Confirm password */}
              <div className="mb-3">
                <label className="form-label">New Password</label>
                <input
                  value={password}
                  name="password"
                  type="password"
                  className="form-control"
                  placeholder="Enter new password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>

              {/*State */}
              <div className="mb-3">
                <label className="form-label">State</label>
                <input
                  value={form?.state}
                  name="state"
                  type="text"
                  className="form-control"
                  placeholder="Enter your state"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              {/* City */}
              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  City
                </label>
                <input
                  value={form?.city}
                  name="city"
                  type="text"
                  className="form-control"
                  placeholder="Enter your city"
                  onChange={handleChange}
                />
              </div>
              {/* DOB */}
              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Date of Birth
                </label>
                <input
                  value={form?.dob}
                  name="dob"
                  type="date"
                  className="form-control"
                  onChange={handleChange}
                />
              </div>
            </div>
            <span className="text-success text-center mb-3">{alert}</span>
            <button
              style={{ backgroundColor: "#000" }}
              type="submit"
              className="payment-btn"
              onClick={updateProfileDetails}
            >
              {loading ? (
                <div className="d-flex align-items-center justify-content-center">
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="ms-2">Loading...</span>
                </div>
              ) : (
                "Update"
              )}
            </button>
          </div>
        </div>
      </Layout>
    </Header>
  );
};

export default Profile;
