import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.css";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import LoginIcon from "@mui/icons-material/Login";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import LockIcon from "@mui/icons-material/Lock";
import SideMenu from "../SideMenu/SideMenu";
import BackDrop from "../SideMenu/BackDrop";
import Footer from "../Footer/Footer";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { SetLogin } from "../../redux/features/loginSlice";
import { SetUser } from "../../redux/features/userSlice";
import IMAGES from "../../img";

const Header = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { login } = useSelector((state) => state.login);
  const { user } = useSelector((state) => state.user);
  const [userData, setUserData] = useState(null);
  const [loginUser, setLoginUser] = useState(false);
  const [sideMenu, setSideMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [supportIcon, setSupportIcon] = useState(false);
  const location = useLocation();

  // const supportIconVisible = () => {
  //   setInterval(() => {
  //     setSupportIcon(!supportIcon);
  //   }, 2000);
  // };
  // useEffect(() => {
  //   supportIconVisible();
  // }, [supportIcon]);

  // ================================ Page Scroll ====================================
  const { pathname } = useLocation();
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
      // eslint-disable-next-line
    }, [pathname]);

    return null;
  };
  ScrollToTop();
  // ================================ Page Scroll ====================================

  // Logout Handler
  const handleLogout = () => {
    setLoading(true);
    axios({
      method: "POST",
      withCredentials: true,
      url: "api/user/logout",
    })
      .then((res) => {
        setTimeout(() => {
          dispatch(SetLogin(false));
          setLoginUser(false);
          setLoading(false);
          navigate("/login");
        }, 1500);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    axios({
      method: "get",
      withCredentials: true,
      url: `api/user/get-user`,
    })
      .then((res) => {
        if (res.data.success) {
          dispatch(SetUser(res.data.data));
          setUserData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    axios({
      method: "get",
      withCredentials: true,
      url: `api/user/isLogin`,
    })
      .then((res) => {
        if (res.data.success) {
          dispatch(SetLogin(true));
          setLoginUser(true);
        }
      })
      .catch((err) => {
        dispatch(SetLogin(false));
        setLoginUser(false);
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <header className="header-main shadow-sm shadow pop">
        {/* Mobile Menu */}
        <SideMenu
          login={login || loginUser}
          user={user || userData}
          sideMenu={sideMenu}
          setSideMenu={setSideMenu}
        />
        <BackDrop
          login={login || loginUser}
          user={user || userData}
          sideMenu={sideMenu}
          setSideMenu={setSideMenu}
        />
        {/* Desktop Menu */}
        <div className="logo">
          <span
            className="text-white cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          >
             

            <img width="200px" className="mob_logo" src={IMAGES.edyguru} alt="" /> 
            {/* <span className="text-warning">Hub</span> */}
            {/* <h2>edyguru</h2> */}
          </span>
        </div>
        {/* Desktop Menu  */}
        <div className={`menu`}>
          <Link className={`${login || loginUser ? "d-none" : ""}`} to="/">
            Home
          </Link>{" "}
          <Link className={`${login || loginUser ? "d-none" : ""}`} to="/about">
            About
          </Link>
          {/* <Link
            className={`${login || loginUser ? "d-none" : ""}`}
            to="/service"
          >
            Services
          </Link> */}
          
          {user?.active || userData?.active ? (
            <Link
              className={`${login || loginUser ? "d-block" : "d-none"}`}
              to="/dashboard"
            >
              Dashboard
            </Link>
          ) : (
            <Link
              className={`${login || loginUser ? "d-block" : "d-none"}`}
              to="/activate"
            >
              Dashboard
            </Link>
          )}
          {(user?.active || userData?.active) && (
            <Link
              className={`${login || loginUser ? "d-block" : "d-none"}`}
              to="/mycourse"
            >
              My Courses
            </Link>
          )}
          {(user?.active || userData?.active) && user?.admin && (
            <Link
              className={`${
                (login || loginUser) && user?.admin ? "d-block" : "d-none"
              }`}
              to="/admin-dashboard"
            >
              Admin
            </Link>
          )}
          {(user?.active || userData?.active) && (
            <Link
              className={`${login || loginUser ? "d-block" : "d-none"}`}
              to="/referlink"
            >
              My Refferal Link
            </Link>
          )}
          {(user?.active || userData?.active) && (
            <Link
              className={`${login || loginUser ? "d-block" : "d-none"}`}
              to="/wallet"
            >
              Wallet
            </Link>
          )}
          <Link to="/contact">Contact</Link>
        </div>
        {/* action buttons  */}
        <div className={`action-btns`}>
          {!login && (
            <div>
              <button
                className="register-btn"
                onClick={() => navigate("/register")}
              >
                Register
                <PersonIcon className="PersonIcon ms-2" />
              </button>
              <button className="login-btn" onClick={() => navigate("/login")}>
                Login
                <LoginIcon className="LoginIcon ms-2" />
              </button>
            </div>
          )}

          {login && (
            <div className="d-flex justify-content-center align-items-center">
              <button
                className={`coin-btn me-2 ${
                  (login || loginUser) && user?.active ? "d-block" : "d-none"
                }`}
                onClick={() => {
                  navigate("/wallet");
                }}
              >
                <span className="">
                  <b>Amount: {user?.amount}</b>
                </span>
              </button>

              <button className="login-btn " onClick={handleLogout}>
                {loading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="ms-2">Loading...</span>
                  </div>
                ) : (
                  "Logout"
                )}
                {!loading && <LoginIcon className="LoginIcon ms-2" />}
              </button>
            </div>
          )}
        </div>

        {/* Burger Icon  */}
        <div className="mobile-menu ">
          {/* Mobile Header Login Button  */}
          <div>
            <div
              className="me-4 d-flex justify-content-center align-items-center p-1 px-2"
              style={{ backgroundColor: "#fff", color: "#000" }}
              onClick={() => {
                navigate(
                  `${
                    login
                      ? user?.active
                        ? "/dashboard"
                        : "/activate"
                      : "/login"
                  }`
                );
              }}
            >
              {!login ? (
                <i
                  className="fa-solid fa-right-to-bracket me-1"
                  onClick={() => setSideMenu(!sideMenu)}
                  style={{ fontSize: "12px" }}
                ></i>
              ) : (
                <LockIcon
                  onClick={() => setSideMenu(!sideMenu)}
                  style={{ fontSize: "14px" }}
                />
              )}
              <span className="ms-1" style={{ fontSize: "14px" }}>
                {login ? "Dashboard" : "Login"}
              </span>
            </div>
          </div>
          <MenuIcon
            className="MenuIcon text-white "
            onClick={() => setSideMenu(!sideMenu)}
          />
        </div>
      </header>
      <div className="children">{children}</div>
      {location.pathname !== "/faqpage" && (
        <div className="support-fixed">
          <span className="d-none d-lg-block">Need Support?</span>
          <div className="support-icon" onClick={() => navigate("/faqpage")}>
            <SupportAgentIcon className="icon" />
          </div>
        </div>
      )}
      <Footer />
    </React.Fragment>
  );
};

export default Header;
