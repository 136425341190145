import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import AdminLayout from "../layout/AdminLayout";
import AdminHeader from "../Header/AdminHeader";
import UserDetails from "./UserDetails";

function AllUsers() {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const profileRef = useRef();
  const navigate = useNavigate();
  const [users, setUsers] = useState(null);
  const [packages, setPackages] = useState(null);
  const [filteredUsers, setFilterUsers] = useState(null);
  const [profile, setProfile] = useState("");

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    const data = users?.filter((user) => {
      const lowerCaseSearchInput = value?.toLowerCase();

      return (
        user.name?.toLowerCase().includes(lowerCaseSearchInput) ||
        user.email?.toLowerCase().includes(lowerCaseSearchInput) ||
        user.number?.toLowerCase().includes(lowerCaseSearchInput) ||
        user.inviteCode?.toLowerCase().includes(lowerCaseSearchInput)
      );
    });
    setFilterUsers(data);
  };

  const handleConfirm = (id, active, pack) => {
    const isConfirmed = window.confirm("Confirmed?");
    if (isConfirmed) {
      handleSubmit(id, active, pack);
    }
  };

  const handleDeleteConfirm = (id) => {
    const isConfirmed = window.confirm("Confirmed?");
    if (isConfirmed) {
      deleteUser(id);
    }
  };

  const handleBigProfile = (photo) => {
    setProfile(photo);
    if (profileRef.current) {
      const modal = new window.bootstrap.Modal(profileRef.current);
      modal.show();
    }
  };

  const deleteUser = (id) => {
    axios({
      method: "POST",
      data: {
        id: id,
      },
      withCredentials: true,
      url: `api/user/delete-user`,
    })
      .then((res) => {
        if (res.data.success) {
          getAllUsers();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = async (id, active, pack) => {
    axios({
      method: "POST",
      data: {
        active: active,
        id: id,
        packageName: pack,
      },
      withCredentials: true,
      url: `api/user/update-user`,
    })
      .then((res) => {
        getAllUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllUsers = async () => {
    axios({
      method: "get",
      withCredentials: true,
      url: `api/user/get-all-users`,
    })
      .then((res) => {
        setUsers(res?.data?.data?.reverse());
        setPackages(res?.data?.package);
        setFilterUsers(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllUsers();
    // eslint-disable-next-line
  }, []);
  return (
    <AdminHeader>
      <AdminLayout>
        {!id ? (
          <div className="tab">
            <div className="welcome-container">
              <div className="username d-flex justify-content-between">
                <span>
                  <b>All</b> Users
                </span>
                <span>
                  <b>{users?.length}</b>
                </span>
              </div>
            </div>
            <div className="welcome-container">
              <div className="username">
                <label className="fw-bold me-2" htmlFor="">
                  Search:{" "}
                </label>
                <input
                  placeholder="Search User"
                  name="search"
                  onChange={handleSearchInputChange}
                  className="p-2"
                  type="text"
                />
              </div>
            </div>
            <div className="w-100 mt-4 " style={{ overflow: "auto" }}>
              <table className="w-100">
                <thead>
                  <tr>
                    <th scope="col" className="">
                      #
                    </th>
                    {/* <th scope="col" className="">
                    Profile
                  </th> */}
                    <th scope="col" className="">
                      Name
                    </th>
                    <th scope="col" className="">
                      Status
                    </th>
                    <th scope="col" className="">
                      Invite Code
                    </th>
                    <th scope="col" className="">
                      Number
                    </th>
                    <th scope="col" className="">
                      Package
                    </th>
                    <th scope="col" className="">
                      Price
                    </th>
                    <th scope="col" className="">
                      Txn ID
                    </th>
                    <th scope="col" className="">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers?.length !== 0 ? (
                    filteredUsers?.map((user, index) => {
                      const active = true;
                      const packagePrice = packages.filter((item) =>
                        item?.packageName.includes(user?.course)
                      );
                      return (
                        <tr key={user?._id} className="border">
                          <td className="px-2 fs-5">
                            <b>{index + 1}</b>
                          </td>
                          <td className="text-dark px-2 fs-6">
                            <Link to={`/admin-users?id=${user._id}`}>
                              {user?.name}
                            </Link>
                          </td>
                          <td
                            className={`text-start text-nowrap px-3 text-center fs-6 ${
                              user?.active ? "text-success" : "text-danger"
                            }`}
                          >
                            {user?.active ? (
                              "Active"
                            ) : (
                              <div
                                onClick={() => {
                                  handleConfirm(
                                    user?._id,
                                    active,
                                    user?.course
                                  );
                                }}
                              >
                                <span className="my-2 cursor-pointer text-white bg-success py-1 px-2">
                                  Activate
                                </span>
                              </div>
                            )}
                          </td>
                          <td
                            className={`text-start fs-6 ${
                              !user?.inviteCode && "text-danger"
                            }`}
                          >
                            {!user?.inviteCode ? "Null" : user?.inviteCode}
                          </td>
                          <td className="text-start fs-6">{user?.number}</td>
                          <td>{user?.course}</td>
                          <td>{packagePrice[0].price}</td>
                          <td>{user?.txnId}</td>
                          <td className="text-start">
                            <div className="text-center py-2">
                              <span
                                className="cursor-pointer fs-6 bg-dark text-white px-1 rounded-2 px-2 py-1"
                                onClick={() => {
                                  handleDeleteConfirm(user._id);
                                }}
                              >
                                Delete
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <p className="text-danger">No Data Found</p>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <UserDetails allUsers={users} />
        )}

        {/* ==============MODAL ============ */}
        <div
          className="modal fade"
          ref={profileRef}
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">User Profile Image</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body w-100">
                <img
                  className="w-100"
                  src={`/profileImages/${profile}`}
                  alt=""
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setProfile("");
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </AdminHeader>
  );
}

export default AllUsers;
