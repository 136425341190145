import React from "react";
import Layout from "../user/layout/Layout";
import Header from "../components/Header/Header";

const Terms = () => {
  return (
    <Header>
      <div className="container p-5">
        <h4>
          <b>Skill Rise Up Course Website Terms and Conditions</b>
        </h4>
        <p>
          Welcome to Skill Rise Up! These Terms and Conditions ("Terms") govern
          your use of the Skill Rise Up Course Website ("www.skillriseup.com").
          By accessing or using the Website, you agree to be bound by these
          Terms.
        </p>
        <b>1.Introduction</b>
        <br /> The Website is operated by Skill Rise Up, hereinafter referred to
        as "we," "us," or "our."
        <br />
        <br />
        <b>2.Eligibility</b>
        <br /> - You must be at least 18 years old to use this Website.
        <br />
        <br />
        <b>3.Account Activation</b>
        <br /> - In order to access certain features and content on the Website,
        you may be required to create an account and pay a one-time activation
        fee. This fee is non-refundable.
        <br />
        <br />
        <b>4.Referral Program</b>
        <br /> - We offer a referral program that allows users to earn rewards
        by referring others to our courses. To obtain a unique referral link,
        users must pay the account activation fee.
        <br />
        <br />
        <b>5.Referral Link Usage</b>
        <br /> - Referral links are intended for personal use only and may not
        be used for any commercial or promotional purposes. Sharing referral
        links on coupon websites, social media advertising, or any other public
        forum is strictly prohibited.
        <br />
        <br />
        <b>6.Reward Structure</b>
        <br /> - Rewards earned through the referral program are subject to
        change and may be updated at our discretion.
        <br />
        <br />
        <b>7.Compliance with Applicable Law</b>
        <br />- You agree to comply with all applicable laws, regulations, and
        industry standards while using the Website.
        <br />
        <br />
        <b>8.Intellectual Property</b>
        <br /> - All content on the Website, including but not limited to text,
        graphics, logos, and images, is the property of Skill Rise Up and
        protected by intellectual property laws.
        <br />
        <br />
        <b>9.Prohibited Activities</b>
        <br /> - You are prohibited from engaging in any unlawful, abusive, or
        harmful activities on the Website, including but not limited to
        spamming, hacking, or distributing malware.
        <br />
        <br />
        <b>10.Privacy Policy</b>
        <br /> - Our Privacy Policy outlines how we collect, use, and disclose
        your personal information. By using the Website, you agree to the terms
        outlined in the Privacy Policy.
        <br />
        <br />
        <b>11. Disclaimer of Warranties</b>
        <br /> - The Website and its content are provided "as is," without any
        warranties, express or implied. We do not guarantee the accuracy,
        reliability, or availability of the Website.
        <br />
        <br />
        <b>12. Limitation of Liability</b>
        <br /> - To the extent permitted by law, we shall not be liable for any
        indirect, consequential, or incidental damages arising out of or in
        connection with your use of the Website.
        <br />
        <br />
        <b>13.Termination</b>
        <br /> - We reserve the right to terminate or suspend your account at
        any time, without prior notice, for any reason.
        <br />
        <br />
        <b>14.Changes to Terms</b>
        <br /> - We may update these Terms from time to time. Any changes will
        be effective immediately upon posting on the Website.
        <br />
        <br />
        <b>15.Governing Law</b>
        <b></b> - These Terms are governed by and construed in accordance with
        the laws of [Jurisdiction], without regard to its conflict of laws
        principles.
        <br />
        <br />
        <b>16. Contact Information</b>
        <br /> - If you have any questions or concerns regarding these Terms,
        you can contact us at [info@Skill Rise Up.com]. By using the Skill Rise
        Up Course Website, you acknowledge that you have read, understood, and
        agree to be bound by these Terms and Conditions.
        <br />
        <br />
        By using the Skill Rise Up Website, you acknowledge that you have read,
        understood, and agree to be bound by these Terms and Conditions.
      </div>
    </Header>
  );
};

export default Terms;
