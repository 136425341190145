import React from "react";
import "../SideMenu/SideMenu.css";
const BackDrop = ({ sideMenu, setSideMenu }) => {
  return (
    <React.Fragment>
      <div
        className={`backdrop ${sideMenu ? "bactive" : ""}`}
        onClick={() => setSideMenu(!sideMenu)}
      ></div>
    </React.Fragment>
  );
};

export default BackDrop;
