import React from "react";
import { Link, useNavigate } from "react-router-dom";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import IMAGES from "../../img";
import "./HeroSection.css";

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      {/* <div className="hero-section container-fluid">
        <img className="float float1" src={IMAGES.money1} alt="" />
        <img className="float float2" src={IMAGES.money1} alt="" />
        <img className="float float3" src={IMAGES.money1} alt="" />
        <img className="float float4" src={IMAGES.money1} alt="" />
        <div className="content">
          <h1>An outstanding platform for pocket money earnings</h1>
          <p>Your go-to platform for earning extra cash.</p>
          <div className="d-flex justify-content-center align-items-center">
            <button
              onClick={() => navigate("/service")}
              className="hero-btn mx-1"
            >
              Services
            </button>
            <button
              onClick={() => navigate("/register")}
              className="hero-btn mx-1"
            >
              Enroll Now
            </button>
          </div>
          <img className="hero-img" width="100%" src={IMAGES.heroBg} alt="" />
        </div>
      </div> */}
      <div className="hero-sec">
        <div className="hero-content">
          <h1>
            Learn, <span>Earn</span>
          </h1>
          <h1>& Repeat..</h1>
          <p className="text-muted text-white">
            An online platform for learning & earning at the same time. Invite
            students and earn an handsome amount!
          </p>
          <div className="hero-action-btn">
            <div className="d-flex justify-content-center align-items-center gap-4">
              <button
                to="/register"
                className="hero-btn-enroll"
                onClick={() => {
                  navigate("/register");
                }}
              >
                Enroll Now
              </button>
              <button
                className="hero-btn-course"
                onClick={() => {
                  navigate("/all-course");
                }}
              >
                Find Course
              </button>
            </div>
            <div className="hero-count">
              <div className="count">
                <h2>50k+</h2>
                <span>Students Enrolled</span>
              </div>
              <div className="count">
                <h2>30+</h2>
                <span>Course Created</span>
              </div>
              <div className="count d-lg-none">
                <h2>1M+</h2>
                <span>Total Coins</span>
              </div>
            </div>
          </div>
          <div className="hero-action-btn mt-4">
            <div className="d-flex justify-content-center align-items-center">
              <AdminPanelSettingsIcon className="text-white fs-2" />
              <span className="text-white">Lifetime Access</span>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <WorkspacePremiumIcon className="text-white fs-2" />
              <span className="text-white">High Value Certification</span>
            </div>
          </div>
        </div>
        <div className="hero-image ">
          <img width={500} src={IMAGES.heroimg3} alt="" />
        </div>
        <div className="hero-image2 "></div>
      </div>
    </React.Fragment>
  );
};

export default HeroSection;
