import React, { useState } from "react";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { Link, useLocation, useParams } from "react-router-dom";
import UserSideMenu from "../UserSideMenu";
import menu from "./UserMenu";
import { useSelector } from "react-redux";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

const Layout = ({ children, setSequence, sequence, loading }) => {
  const params = useParams();
  const location = useLocation();
  const { allCourseVideos } = useSelector((state) => state.allCourseVideos);
  const { user } = useSelector((state) => state.user);
  const [userMenu, setUserMenu] = useState(false);
  const CourseMenu = allCourseVideos?.map((video, index) => ({
    name: video.title,
    seq: index + 1,
    icon: PlayCircleIcon, // You can specify the icon here
  }));

  const coursePageMenu =
    location.pathname === `/course/${params.courseName}` && CourseMenu;
  const showMenu =
    location.pathname === `/course/${params.courseName}` ? CourseMenu : menu;

  return (
    <div className="dashboard container-fluid">
      <div className="left d-lg-block d-none d-md-none">
        <ul>
          {coursePageMenu
            ? coursePageMenu?.map((item, index) => {
                const IconComponent = item.icon;
                return (
                  <>
                    {!loading ? (
                      <li
                        className={`${sequence === item.seq ? "active" : ""}`}
                      >
                        <IconComponent />
                        <Link
                          className={`ms-2`}
                          to="#"
                          onClick={() => {
                            setSequence(item.seq);
                          }}
                        >
                          {item.name}
                        </Link>
                      </li>
                    ) : (
                      <div className="d-flex ">
                        <span
                          className="spinner-grow spinner-grow-sm my-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    )}
                  </>
                );
              })
            : showMenu?.map((item, index) => {
                const IconComponent = item.icon;
                return (
                  <li
                    className={`${
                      location.pathname === `${item.path}` ? "active" : ""
                    }`}
                  >
                    <IconComponent />
                    <Link className="ms-2 position-relative" to={item.path}>
                      {((item.name === "My Profile" &&
                        (!user?.state || !user?.city || !user?.photo)) ||
                        (item.name === "Payment Method" && !user?.payment)) && (
                        <>
                          {
                            <span
                              className=" rounded-circle bg-danger p-1"
                              style={{
                                position: "absolute",
                                right: "-20px",
                                top: "6px",
                              }}
                            >
                              <span className="visually-hidden">
                                unread messages
                              </span>
                            </span>
                          }
                        </>
                      )}
                      {item.name}
                    </Link>
                  </li>
                );
              })}
        </ul>
      </div>
      <div className="right">
        <div className="py-3 d-block d-md-block d-lg-none">
          <MenuOpenIcon
            className="menuIcon"
            onClick={() => setUserMenu(!userMenu)}
          />
          <UserSideMenu
            userMenu={userMenu}
            setUserMenu={setUserMenu}
            CourseMenu={CourseMenu}
            sequence={sequence}
            setSequence={setSequence}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Layout;
