import React, { useEffect, useState } from "react";
import axios from "axios";
import AdminLayout from "../layout/AdminLayout";
import AdminHeader from "../Header/AdminHeader";

function Payments() {
  const [payments, setPayments] = useState(null);

  const getAllPayments = async () => {
    axios({
      method: "get",
      withCredentials: true,
      url: `api/admin/get-all-payments`,
    })
      .then((res) => {
        setPayments(res?.data?.data?.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllPayments();
    // eslint-disable-next-line
  }, []);
  return (
    <AdminHeader>
      <AdminLayout>
        <div className="tab">
          <div className="welcome-container">
            <div className="username d-flex justify-content-between">
              <span>
                <b>All</b> Payments
              </span>
              <span>
                Total Payments: <b>{payments?.length}</b>
              </span>
              <span>
                Total Amount:{" "}
                <b>
                  {payments?.reduce(
                    (acc, obj) => acc + parseFloat(obj.amount),
                    0
                  )}
                </b>
              </span>
            </div>
          </div>
          <div className="w-100 mt-4 " style={{ overflow: "auto" }}>
            <table className="w-100">
              <thead>
                <tr>
                  <th scope="col" className="">
                    #
                  </th>
                  <th scope="col" className="">
                    Name
                  </th>
                  <th scope="col" className="">
                    Email
                  </th>
                  <th scope="col" className="">
                    Number
                  </th>
                  <th scope="col" className="">
                    Amount
                  </th>
                  <th scope="col" className="">
                    Pay Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {payments?.map((user, index) => {
                  const active = true;
                  return (
                    <tr key={user?._id} className="border">
                      <td className="px-2 fs-5">
                        <small>{index + 1}</small>
                      </td>
                      <td className="cursor-pointer">
                        <small>{user?.name}</small>
                      </td>
                      <td className="text-dark px-2 fs-6">
                        <small>{user?.email}</small>
                      </td>
                      <td
                        className={`text-start text-nowrap px-3 text-center fs-6`}
                      >
                        <small>{user?.number}</small>
                      </td>
                      <td className="text-start fs-6">
                        <small>{user?.amount}</small>
                      </td>
                      <td className="text-start">
                        <small>{user?.payDate}</small>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </AdminLayout>
    </AdminHeader>
  );
}

export default Payments;
