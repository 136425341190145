import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoneIcon from "@mui/icons-material/Done";
import "./CourseDetail.css";

const CourseDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [packages, setPackages] = useState(null);
  const [singlePackage, setSinglePackage] = useState(null);
  const [courses, setCourses] = useState(null);

  let pName;
  pName = params?.name;

  async function getAllPackages() {
    try {
      const res = await axios.get("/api/package/get-all-package");
      if (res.data.success) {
        setPackages(res.data.data);
        setSinglePackage(
          res.data.data.filter((item) => item?.packageName === pName)
        );
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getCoursesByName() {
    try {
      const res = await axios.post("/api/course/get-courses-by-name", {
        courses: singlePackage[0].courseName,
      });
      if (res.data.success) {
        setCourses(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllPackages();
  }, []);

  useEffect(() => {
    if (singlePackage !== null) {
      getCoursesByName();
    }
  }, [singlePackage]);

  return (
    <Header>
      <div className="course-detail-page">
        <div className="course-overview">
          <h4 className="mb-4">
            <b>Course Overview</b>
          </h4>
          <div className="course-contentttt">
            <h5>Course Content</h5>
            <div>
              <span className="me-3">
                <MenuBookIcon className="icon me-2" />
                {singlePackage?.courseName?.split(",").length} Courses
              </span>
              <span>
                <AccessTimeIcon className="icon me-1" />
                {singlePackage && singlePackage[0]?.duration}
              </span>
            </div>
          </div>
          <div className="course-info">
            {courses?.map((item, index) => {
              return <div className="course-card">{item?.courseName}</div>;
            })}
          </div>
        </div>
        <div className="course-buy-container">
          <img src={singlePackage && singlePackage[0]?.image} alt="" />
          <h2>
            <b>Rs {singlePackage && singlePackage[0]?.price}</b>
          </h2>
          <button
            className="button w-100"
            onClick={() => navigate("/register")}
          >
            Buy Now
          </button>
        </div>
      </div>
    </Header>
  );
};

export default CourseDetail;
