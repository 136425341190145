import React, { useEffect, useRef, useState } from "react";
import "./PaymentMethod.css";
import Layout from "./layout/Layout";
import Header from "../components/Header/Header";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { SetUser } from "../redux/features/userSlice";
import IMAGES from "../img";
import { useNavigate } from "react-router-dom";

const PaymentMethod = () => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [form, setForm] = useState(null);
  const [tab, setTab] = useState(0);
  const { user } = useSelector((state) => state.user);

  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const updatePaymentDetails = (e) => {
    e.preventDefault();
    setLoading(true);
    axios({
      method: "POST",
      data: {
        form: form,
        id: user?._id,
      },
      withCredentials: true,
      url: `api/user/update-payment-details`,
    })
      .then((res) => {
        if (res.data.success) {
          setTimeout(() => {
            setLoading(false);
            setAlert("Updated Successully");
            dispatch(SetUser(res.data.data));
          }, 1500);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (user) {
      setForm({ ...user?.payment });
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 3000);
    }
    // eslint-disable-next-line
  }, [alert]);

  useEffect(() => {
    const isMobileView = window.innerWidth < 768;

    if (isMobileView) {
      const images = document.querySelectorAll(".pay-img");
      images.forEach((image) => {
        image.addEventListener("click", scrollToForm);
      });
    }
  }, []);
  return (
    <Header>
      <Layout>
        {/* welcome container  */}
        <div className="welcome-container">
          <h5>Payment Methods</h5>
          <hr />
          <div className="payment-methods">
            <div
              className="pay-img"
              onClick={() => {
                setTab(0);
                setAlert("");
              }}
            >
              <img src={IMAGES.ba} alt="" />
            </div>
            <div
              className="pay-img"
              onClick={() => {
                setTab(1);
                setAlert("");
              }}
            >
              <img src={IMAGES.easypaisa} alt="" />
            </div>
            <div
              className="pay-img"
              onClick={() => {
                setTab(2);
                setAlert("");
              }}
            >
              <img src={IMAGES.jcash} alt="" />
            </div>
          </div>
          <div className="payment-form-container" ref={formRef}>
            <div className="">
              <form
                className="bank pay-method"
                action=""
                onSubmit={updatePaymentDetails}
              >
                {tab === 0 && (
                  <>
                    <label>Bank Account</label>
                    <input
                      value={form?.name}
                      name="name"
                      className="rounded-0 border"
                      type="text"
                      placeholder="Bank Name"
                      onChange={onChange}
                    />
                    <input
                      value={form?.account}
                      name="account"
                      className="rounded-0 border"
                      type="text"
                      placeholder="Account Title"
                      onChange={onChange}
                    />
                    <input
                      value={form?.ifsc}
                      name="ifsc"
                      className="rounded-0 border"
                      type="text"
                      placeholder="Account Number/IBN"
                      onChange={onChange}
                    />
                  </>
                )}
                {tab === 1 && (
                  <>
                    <label>Easy Paisa</label>
                    <input
                      value={form?.easyPaisaName}
                      name="easyPaisaName"
                      className="rounded-0 border"
                      type="text"
                      placeholder="Account Title"
                      onChange={onChange}
                    />
                    <input
                      value={form?.easyPaisaAccount}
                      name="easyPaisaAccount"
                      className="rounded-0 border"
                      type="text"
                      placeholder="Account Number"
                      onChange={onChange}
                    />
                    {/* <input
                      value={form?.easyPaisaIfsc}
                      name="easyPaisaIfsc"
                      className="rounded-0 border"
                      type="text"
                      placeholder="IFSC Code"
                      onChange={onChange}
                    /> */}
                  </>
                )}
                {tab === 2 && (
                  <>
                    <label>Jazz Cash</label>
                    <input
                      value={form?.jazzCashaName}
                      name="jazzCashaName"
                      className="rounded-0 border"
                      type="text"
                      placeholder="Account Title"
                      onChange={onChange}
                    />
                    <input
                      value={form?.jazzCashaAccount}
                      name="jazzCashaAccount"
                      className="rounded-0 border"
                      type="text"
                      placeholder="Account Number"
                      onChange={onChange}
                    />
                    {/* <input
                      value={form?.jazzCashaIfsc}
                      name="jazzCashaIfsc"
                      className="rounded-0 border"
                      type="text"
                      placeholder="IFSC Code"
                      onChange={onChange}
                    /> */}
                  </>
                )}
                {tab === 3 && (
                  <>
                    <label>Phonepe Number</label>
                    <input
                      value={form?.phonepe}
                      name="phonepe"
                      className="rounded-0 border"
                      type="text"
                      placeholder="Phonepe Number"
                      onChange={onChange}
                    />
                  </>
                )}
                {tab === 4 && (
                  <>
                    <label>UPI</label>
                    <input
                      value={form?.upi}
                      name="upi"
                      className="rounded-0 border"
                      type="text"
                      placeholder="UPI ID"
                      onChange={onChange}
                    />
                  </>
                )}
                <span className="text-success">{alert}</span>
                <button
                  type="submit"
                  className="payment-btn"
                  style={{ backgroundColor: "#000" }}
                >
                  {loading ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="ms-2">Loading...</span>
                    </div>
                  ) : (
                    "Update"
                  )}
                </button>
              </form>
              <p className="text-dark">
                Note: Please fill the details properly
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </Header>
  );
};

export default PaymentMethod;
