import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import "./Register.css";
import { Link, useNavigate } from "react-router-dom";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { SetUser } from "../redux/features/userSlice";
import { SetAdmin } from "../redux/features/adminSlice";
// import { message } from "antd";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  // const [toggle, setToggle] = useState(true);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    axios({
      method: "get",
      withCredentials: true,
      url: `api/user/isLogin`,
    })
      .then((res) => {
        if (res.data.success) {
          axios({
            method: "get",
            withCredentials: true,
            url: `api/user/get-user`,
          })
            .then((res) => {
              if (res.data.success) {
                if (res.data.data?.admin === true) {
                  dispatch(SetAdmin(true));
                  return navigate("/admin-dashboard");
                } else if (!res.data.data?.active) {
                  navigate("/activate");
                } else {
                  navigate("/dashboard");
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    axios({
      method: "POST",
      data: {
        email: form.email,
        password: form.password,
      },
      withCredentials: true,
      url: `/api/user/login`,
    })
      .then((res) => {
        setTimeout(() => {
          dispatch(SetUser(res.data.data));
          setLoading(false);
          setMessage(null);
          if (res.data.data?.admin === true) {
            dispatch(SetAdmin(true));
            return navigate("/admin-dashboard");
          } else if (res.data.data?.active) {
            navigate("/dashboard");
          } else {
            setMessage(res.data.message);
            setTimeout(() => {
              setMessage(null);
            }, 1500);
          }
        }, 1500);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.success === false) {
          setMessage(err.response.data.message);
        }
        console.log(err);
      });
  };

  return (
    <Header>
      <div className="container-fluid bg-light register-form-container">
        <div className="login-form">
          <div className="row">
            <div className="register-img col-12 col-sm-12 col-md-4 col-lg-4">
              <div className="pcolor rounded-pill p-3">
                <VpnKeyIcon className="text-back" />
              </div>
              <div>
                <p className="fs-3 fw-bold m-0 mt-3">Login</p>
              </div>
            </div>
            <div className="login-form-fields-container col-12 col-sm-12 col-md-8 col-lg-8 ">
              <form onSubmit={submitHandler}>
                <h5 className="mb-4 position-relative line2">
                  Welcome to The SkillRiseUp!
                </h5>
                {/* email  */}
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email address
                  </label>
                  <input
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Enter your email"
                    required
                    onChange={handleChange}
                  />
                </div>
                {/* password */}
                <div className="mb-3">
                  <div className="d-flex justify-content-between">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label"
                    >
                      Password
                    </label>
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label"
                    >
                      <Link className="text-decoration-none" to="/forgotPass">
                        Forgot Password?
                      </Link>
                    </label>
                  </div>
                  <input
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="Enter your password"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="col-auto mb-3">
                  <span
                    id="passwordHelpInline"
                    className="form-text text-danger"
                  >
                    {message}
                  </span>
                </div>
                <button type="submit" className="reg-btn w-100 mt-3">
                  {loading ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="ms-2">Loading...</span>
                    </div>
                  ) : (
                    "Login"
                  )}
                </button>
                <div className="login-with text-center m-auto w-100">
                  <span className="text-center my-3 d-flex align-items-center justify-content-center">
                    <span className="mx-3 hr-line"></span>
                    <span className="">or</span>
                    <span className="mx-3 hr-line"></span>
                  </span>
                  <Link to="/register" type="submit">
                    <span>New to The SkillRiseUp? Register here </span>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default Login;
