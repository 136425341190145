import React from "react";
import Header from "../components/Header/Header";

const Refund = () => {
  return (
    <Header>
      <div className="container p-5">
        <h4>
          <b>Refund Policy for Skill Rise Up Course Website</b>
        </h4>
        <p>
          Welcome to Skill Rise Up! This Refund Policy outlines the terms and
          conditions regarding refunds for our courses. By accessing or using
          the Skill Rise Up Course Website ("www.skillriseup.com"), you agree to
          be bound by this policy.
        </p>
        <b>1.Refund Eligibility</b>
        <br />
        - We offer refunds for course fees in the following circumstances:
        <br /> a. Technical Issues: If you encounter technical difficulties that
        prevent you from accessing the course content despite reasonable efforts
        to resolve the issue with our support team. <br /> b. Dissatisfaction:
        If you are unsatisfied with the course content, you may request a refund
        within [number of days, e.g., 14 days] of purchase. A valid reason for
        dissatisfaction must be provided.
        <br />
        <br />
        <b>2.Refund Process</b>
        <br /> - To request a refund, you must contact our support team at
        [info@Skill Rise Up.com] within the specified refund period. Please
        provide the following information: <br /> a. Your name and contact
        information. <br /> b. Course name and date of purchase. <br /> c.
        Reason for the refund request, along with any supporting documentation.
        <br />
        Our support team will review your request and respond within [number of
        business days, e.g., 5 business days] with further instructions.
        <br /> If your refund request is approved, the refund will be processed
        to the original payment method within [number of days, e.g., 10 business
        days].
        <br />
        <br />
        <b>3.Non-Refundable Items</b>
        <br /> - The following items are non-refundable: <br /> a. Account
        Activation Fee: The one-time fee paid for account activation is
        non-refundable. <br /> b. Referral Program Payments: Rewards earned
        through the referral program are not eligible for refunds.
        <br />
        <br />
        <b>4.Course Completion</b>
        <br /> - If you have completed a significant portion of the course
        content, you may not be eligible for a refund. This determination will
        be made at our discretion.
        <br />
        <br />
        <b>5.Additional Terms</b>
        <br /> - We reserve the right to deny any refund request if we believe
        it is not in accordance with this policy. <br /> This refund policy does
        not affect your statutory rights as a consumer.
        <br />
        <br />
        <b>6.Contact Us</b>
        <br /> - If you have any questions or concerns about this Refund Policy,
        please contact us at [Contact Email Address].
        <br />
        <br />
        By using the Skill Rise Up Website, you acknowledge that you have read,
        understood, and agree to the terms outlined in this Refund Policy.
      </div>
    </Header>
  );
};

export default Refund;
