import React from "react";
import Header from "../components/Header/Header";
import Layout from "./layout/Layout";
import "./ComissionStructure.css";
import IMAGES from "../img";

const ComisionStructure = () => {
  return (
    <Header>
      <Layout>
        <div className="comm-banner">
          <img src={IMAGES.comBanner} alt="" />
        </div>
        <p className="mt-3 text-center">
          For every successful referral you get 500Rs and more offers are
          available on home page.
        </p>
        {/* <div className="comission-table mt-5">
          <thead>
            <tr>
              <th width="10%">Type</th>
              <th width="30%">Course Price</th>
              <th width="10%">Comission</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-label="Course Name">Activation</td>
              <td data-label="Price">
                Successfull Account Activation of a person you invite
              </td>
              <td data-label="Comission">60% of Total Amount</td>
            </tr>
            <tr>
              <td data-label="Course Name">Course</td>
              <td data-label="Price">
                When your invitee succesfully purchased a course
              </td>
              <td data-label="Comission">50% of Total Amount</td>
            </tr>
          </tbody>
        </div> */}
      </Layout>
    </Header>
  );
};

export default ComisionStructure;
