import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ReportIcon from "@mui/icons-material/Report";
import InfoIcon from "@mui/icons-material/Info";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import IMAGES from "../img";
import Layout from "./layout/Layout";
import axios from "axios";
import "./Dashboard.css";

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const [referredUser, setReferredUser] = useState(null);
  const [packages, setPackages] = useState(null);

  function firstLCapital(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }

  const calculateEarningForPeriod = (referredUsers, daysAgo) => {
    let earningForPeriod = 0;
    const today = new Date();
    const startDate = new Date(today);
    startDate.setDate(startDate.getDate() - daysAgo);

    const referredUsersInPeriod = referredUsers?.filter((user) => {
      const userJoinDate = new Date(user.createdAt);
      return userJoinDate >= startDate && userJoinDate <= today;
    });

    console.log(referredUsersInPeriod);

    referredUsersInPeriod?.forEach((user) => {
      if (user.course && user.course.length > 0) {
        const pack = packages.find(
          (item) => item.packageName === user.course[0]
        );
        if (pack) {
          earningForPeriod += parseInt(pack.price);
        }
      }
    });
    return earningForPeriod / 2;
  };

  const todaysEarning = calculateEarningForPeriod(referredUser, 1);
  const lastWeekEarning = calculateEarningForPeriod(referredUser, 7);
  const monthlyEarning = calculateEarningForPeriod(referredUser, 30);

  const getAllReferredUser = () => {
    axios({
      method: "POST",
      data: {
        inviteCode: user?.inviteCode,
      },
      withCredentials: true,
      url: `api/user/get-all-referred-user`,
    })
      .then((res) => {
        if (res.data.success) {
          setReferredUser(res.data.data.reverse());
          setPackages(res.data.package);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user) {
      getAllReferredUser();
    }
  }, [user]);

  return (
    <Header>
      <Layout>
        <div className="dashboard-container">
          {/* welcome container  */}
          <div className="welcome-container">
            <h5>Hi {firstLCapital(user?.name)}</h5>
            <hr />
            <div className="username">
              <span>
                <b>Welcome</b> Skill Rise Up
              </span>
            </div>
          </div>
          {/* earning cards  */}
          <div className="d-flex flex-wrap gap-2 justify-content-start">
            <div
              className="dashboard-card"
              onClick={() => {
                navigate("/wallet");
              }}
            >
              {/* <div className="icon-cont">
                <CurrencyRupeeIcon className="icon" />
              </div> */}
              <div className="d-content text-start">
                <p className="m-0 mb-2">
                  <small>Today's Earning</small>
                </p>
                <h3>
                  <b>{todaysEarning}</b>
                </h3>
              </div>
            </div>
            <div
              className="dashboard-card"
              onClick={() => {
                navigate("/wallet");
              }}
            >
              {/* <div className="icon-cont">
                <CurrencyRupeeIcon className="icon" />
              </div> */}
              <div className="d-content text-start">
                <p className="m-0 mb-2">
                  <small>Last 7 Day's Earning</small>
                </p>
                <h3>
                  <b>{lastWeekEarning}</b>
                </h3>
              </div>
            </div>
            <div
              className="dashboard-card"
              onClick={() => {
                navigate("/wallet");
              }}
            >
              {/* <div className="icon-cont">
                <CurrencyRupeeIcon className="icon" />
              </div> */}
              <div className="d-content text-start">
                <p className="m-0 mb-2">
                  <small>Last 30 Day's Earning</small>
                </p>
                <h3>
                  <b>{monthlyEarning}</b>
                </h3>
              </div>
            </div>

            <div
              className="dashboard-card"
              onClick={() => {
                navigate("/wallet");
              }}
            >
              {/* <div className="icon-cont">
                <CurrencyRupeeIcon className="icon" />
              </div> */}
              <div className="d-content text-start">
                <p className="m-0 mb-2">
                  <small>Total Earnings</small>
                </p>
                <h3>
                  <b>{user?.earning}</b>
                </h3>
              </div>
            </div>
            <div
              className="dashboard-card"
              onClick={() => {
                navigate("/wallet");
              }}
            >
              {/* <div className="icon-cont">
                <AccountBalanceWalletIcon className="icon" />
              </div> */}
              <div className="d-content text-start">
                <p className="m-0 mb-2">
                  <small>Balance</small>
                </p>
                <h3>
                  <b>{user?.amount}</b>
                </h3>
              </div>
            </div>
            <div
              className="dashboard-card"
              onClick={() => navigate("/records/referral")}
            >
              <div className="d-content text-start">
                <p className="m-0 mb-2">
                  <small>Total Referrals</small>
                </p>
                <h3>
                  <b>{referredUser?.length}</b>
                </h3>
              </div>
            </div>
          </div>
          {/* profile */}
          <div className="profile-sales">
            <div className="profile text-center">
              {user?.photo ? (
                <img
                  className="userImage"
                  src={`/profileImages/${user?.photo}`}
                  alt=""
                />
              ) : (
                <img className="userImage" src="#" alt="" />
              )}
              <p className="text-center m-0 fs-4">
                <strong>{firstLCapital(user?.name)}</strong>
              </p>
              <p className="text-muted text-center">
                <small>{user?.email}</small>
              </p>
              <p>Plan: {user?.course[0]}</p>
              <div className="wallet-container">
                <div className="wallet-card">
                  <div className="text-white d-flex justify-content-end">
                    <span>wallet</span>
                    <MoreVertIcon className="icon" />
                  </div>
                  <div className="instant-money text-start text-white">
                    <h6>Instant Money</h6>
                    <img src={IMAGES.chip} alt="" />
                    <h6 className="mt-3">
                      Redeemed Amount <RemoveRedEyeIcon />
                    </h6>
                    <h4>
                      <b> {user?.withdrawAmount}</b>
                    </h4>
                    <div className="card-name-logo">
                      <div className="name">
                        <span>
                          {user?.inviteCode} <InfoIcon />
                        </span>
                        <br />
                        <p className="mt-2 fw-bold fs-6">{user?.name}</p>
                      </div>
                      {/* <div className="bblogo">
                        <img width="200px" src={IMAGES.walletbb} alt="" />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sales">
              <h5>Recent Sales</h5>
              {referredUser?.length !== 0 ? (
                <div className="recent-sales">
                  {referredUser?.map((user, index) => {
                    return (
                      <div className="sale py-2">
                        <p className="m-0 mb-2 recent-sale-index fw-bold">
                          {index + 1}
                        </p>
                        <p className="recent-sale-name m-0">
                          {firstLCapital(user?.name)}
                        </p>
                        <p className="recent-sale-number m-0">
                          <a href={`tel:${user?.number}`} className="text-dark">
                            {user?.number}
                          </a>
                        </p>
                        <p>
                          <strong
                            className={`${
                              user?.active
                                ? "text-success recent-sale-status m-0"
                                : "text-danger recent-sale-status m-0"
                            }`}
                          >
                            {user?.active ? "Active" : "Inactive"}
                          </strong>
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="text-center mt-5 pt-5 d-flex justify-content-center align-items-center">
                  <ReportIcon />
                  <span>'No record found'</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </Header>
  );
};

export default Dashboard;
