import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import axios from "axios";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { message, Modal, Button } from "antd";
import "./Register.css";

const Register = () => {
  const navigate = useNavigate();
  const { refCode } = useParams();
  const inputRef = useRef(null);
  const [login, setLogin] = useState(null);
  const [paramsRefCode, setParamsRefCode] = useState(refCode);
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [message2, setMessage2] = useState(null);
  const [message3, setMessage3] = useState(null);
  const [message4, setMessage4] = useState(null);
  const [message5, setMessage5] = useState(null);
  const [message6, setMessage6] = useState(null);
  const [message7, setMessage7] = useState(null);
  const [otp, setOtp] = useState(null);
  const [referralUser, setReferralUser] = useState(null);
  const [referralCode, setReferralCode] = useState("");
  const [photo, setPhoto] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [register, setRegister] = useState(false);

  const [packages, setPackages] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const [visible, setVisible] = useState(false);
  const [bankModal, setBankModal] = useState(false);
  const [cantRegisterModal, setCantRegisterModal] = useState(false);
  const [txnId, setTxnId] = useState("");

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = (val) => {
    if (val === "asking") {
      setVisible(false);
      setCantRegisterModal(false);
      setBankModal(true);
    }
    if (val === "warning") {
      setVisible(false);
      setCantRegisterModal(false);
      setBankModal(false);
    }
    if (val === "bank") {
      setVisible(false);
      setCantRegisterModal(false);
      setBankModal(false);
    }
  };
  const handleCancel = (val) => {
    if (val === "asking") {
      setVisible(false);
      setCantRegisterModal(true);
      setBankModal(false);
    }
    if (val === "warning") {
      setCantRegisterModal(false);
      setBankModal(false);
    }
    if (val === "bank") {
      setBankModal(false);
    }
  };

  async function getAllPackages() {
    try {
      const res = await axios.get("/api/package/get-all-package");
      if (res.data.success) {
        setPackages(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllPackages();
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const checkFileSize = (e) => {
    setMessage3(null);
    if (e.target.files[0]) {
      const fileSize = e.target.files[0]?.size;
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes
      if (fileSize > maxSize) {
        setMessage3("File size exceeds 2MB. Please select a smaller file.");
        setPhoto(null);
        return false;
      }
    }
    setPhoto(e.target.files[0]);
    return true;
  };

  const handleChange = (e, value) => {
    if (value === "password") {
      if (e.target.value?.length < 8) {
        setMessage5("Must be 8-20 characters long.");
      } else {
        setMessage5(null);
      }
    }
    if (value === "number") {
      console.log(e.target.value?.length);
      if (e.target.value?.length === 10) {
        setMessage6(null);
      } else {
        setMessage6("Enter 10 digits mobile number");
      }
    }
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    axios({
      method: "get",
      withCredentials: true,
      url: `api/user/isLogin`,
    })
      .then((res) => {
        if (res.data.success) {
          axios({
            method: "get",
            withCredentials: true,
            url: `api/user/get-user`,
          })
            .then((res) => {
              if (res.data.success) {
                if (!res.data.data?.active) {
                  navigate("/activate");
                } else {
                  navigate("/dashboard");
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  const getReferralUser = () => {
    setMessage2(null);
    setLoading2(true);
    axios({
      method: "POST",
      data: {
        referralCode: paramsRefCode || referralCode,
      },
      withCredentials: true,
      url: `/api/user/get-referral-user`,
    })
      .then((res) => {
        if (res.data.success) {
          setTimeout(() => {
            setMessage2(`Name: ${res.data?.data?.name}`);
            setLoading2(false);
            setReferralUser(res.data.data);
            setRegister(true);
          }, 1500);
        }
      })
      .catch((err) => {
        setTimeout(() => {
          setMessage2(`invalid code`);
          setLoading2(false);
        }, 1500);
        console.log(err);
      });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (photo === null) {
      return message.error("Please select an Image");
    }
    if (selectedPackage === null) {
      return message.error("Please select a Package");
    }
    if (txnId === "") {
      return message.error("Enter Transaction ID");
    }
    if (isNaN(form?.number)) {
      return setMessage6("Enter only numbers");
    }
    if (!photo) {
      return setMessage3("Please select profile image");
    } else if (isChecked === false) {
      return message.error("Please check terms & conditions.");
    } else if (
      form?.password?.length < 8 ||
      !/\d/.test(form?.password) ||
      !/[a-zA-Z]/.test(form?.password)
    ) {
      return setMessage5(
        "Password should be at least 8 characters long and contain at least 1 number and 1 letter."
      );
    }
    setMessage4(null);
    setMessage5(null);
    setLoading(true);
    const formData = new FormData();
    formData.append("photo", photo);
    formData.append("name", form?.name);
    formData.append("number", form?.number);
    formData.append("email", form?.email);
    formData.append("referralCode", paramsRefCode || referralCode);
    formData.append("password", form?.password);
    formData.append("course", selectedPackage?.packageName);
    formData.append("txnId", txnId);
    axios({
      method: "POST",
      data: formData,
      withCredentials: true,
      url: `/api/user/register`,
    })
      .then((res) => {
        if (res.data.error === "userExists") {
          setMessage7("User already exist");
          setLoading(false);
        } else {
          setTimeout(() => {
            navigate("/login");
            setLoading(false);
          }, 1500);
          setMessage7(null);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  // GENERATE OTP
  const generateOTP = () => {
    const digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 6; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    return setOtp(OTP);
  };

  useEffect(() => {
    generateOTP();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (refCode) {
      getReferralUser();
    }
    //eslint-disable-next-line
  }, [refCode]);

  return (
    <Header>
      {!login && (
        <div className="container-fluid register-form-container">
          <div className="form ">
            <div className="row w-100">
              <div className="register-img col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="pcolor rounded-pill p-3">
                  <PersonAddIcon className="text-back" />
                </div>
                <div>
                  <p className="fs-3 fw-bold m-0 mt-3">Register Now</p>
                </div>
              </div>
              <div className="register-form-fields-container col-12 col-sm-12 col-md-8 col-lg-8">
                <form onSubmit={submitHandler}>
                  {/* <h5 className="mb-4 position-relative line">
                    Register to Work Mania
                  </h5> */}
                  <div className="row">
                    {/* Photo  */}
                    <div className="col-md-2 col-12 p-0">
                      {photo ? (
                        <div
                          className="profile-photo  col-12"
                          id="user-profile-photo"
                        >
                          <img src={URL.createObjectURL(photo)} alt="" />
                        </div>
                      ) : (
                        <div
                          id="user-profile-photo"
                          className="profile-photo col-12"
                        >
                          <AddPhotoAlternateIcon
                            style={{ fontSize: "53px", color: "black" }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="mb-3 px-1 col-md-10 col-12">
                      <label
                        htmlFor="user-profile-photo"
                        className="form-label"
                      >
                        Upload Photo
                      </label>
                      <input
                        id="user-profile-photo"
                        name="name"
                        accept=".jpeg, .jpg, .png"
                        maxlength="2097152"
                        type="file"
                        className="form-control"
                        onChange={checkFileSize}
                        ref={inputRef}
                      />
                      <p className="m-0 mt-1 text-danger">{message3}</p>
                    </div>

                    {/* Name  */}
                    <div className="mb-3 px-1 col-md-6 col-12">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Full Name
                      </label>
                      <input
                        name="name"
                        type="text"
                        className="form-control"
                        required
                        placeholder="Enter your name"
                        onChange={handleChange}
                      />
                    </div>
                    {/* email  */}
                    <div className="mb-3 px-1 col-md-6 col-12">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Email address
                      </label>
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        required
                        placeholder="Enter you email"
                        onChange={handleChange}
                      />
                    </div>

                    {/* Mobile Number  */}
                    <div className="mb-3 px-1 col-md-6 col-12 p-0">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Mobile Number
                      </label>
                      <input
                        maxLength={10}
                        minLength={10}
                        type="text"
                        name="number"
                        className="form-control"
                        required
                        placeholder="Enter your number"
                        onChange={(e) => {
                          handleChange(e, "number");
                        }}
                      />
                      <div className="col-auto mb-2">
                        <label className="form-check-label text-danger">
                          {message6}
                        </label>
                      </div>
                    </div>
                    {/* password */}
                    <div className="col-md-6 px-1 col-12 p-0">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Enter your password"
                        required
                        onChange={(e) => {
                          handleChange(e, "password");
                        }}
                      />
                      <div className="col-auto mb-2">
                        <label className="form-check-label text-danger">
                          {message5}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* Ref Code  */}
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="col-9 ps-1">
                      <label className="form-label">Enter Referral Code</label>
                      <input
                        name="referralCode"
                        type="text"
                        className="form-control"
                        placeholder="Enter referral code"
                        value={paramsRefCode || referralCode}
                        onChange={(e) => {
                          setReferralCode(e.target.value);
                        }}
                        disabled={referralUser}
                      />
                    </div>
                    <div className="col-3 pe-1">
                      <label className="form-label text-primary text-center">
                        {referralUser && "Code Applied Successfully!!"}
                      </label>
                      {!referralUser ? (
                        <button
                          disabled={!referralCode}
                          type="button"
                          className="border-0 rounded-0 text-white w-100"
                          style={{
                            backgroundColor: "#000",
                            padding: "8.2px",
                          }}
                          onClick={getReferralUser}
                        >
                          {loading2 ? (
                            <div className="d-flex align-items-center justify-content-center">
                              <span
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="ms-2">Wait</span>
                            </div>
                          ) : (
                            "Search"
                          )}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="border-0 rounded-0 text-white w-100"
                          style={{
                            backgroundColor: `${
                              referralUser ? "gray" : "#080f2a"
                            }`,
                            padding: "8.2px",
                          }}
                          onClick={() => {
                            setReferralUser(null);
                            setReferralCode("");
                            setMessage2(null);
                            setParamsRefCode(null);
                            setRegister(false);
                          }}
                        >
                          Clear
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="col-auto mb-3">
                    <span
                      id="passwordHelpInline"
                      className={`form-text ${
                        referralUser ? "text-primary" : "text-danger"
                      }`}
                    >
                      {message2}
                    </span>
                  </div>
                  <div className="col-auto mb-3">
                    <span
                      id="passwordHelpInline"
                      className="form-text text-danger"
                    >
                      {message7}
                    </span>
                  </div>
                  {/* <div className="my-3 mx-1">
                    <label className="form-check-label">
                      Your details will be used to process your order, support
                      your experience throughout this website, and for other
                      purposes described in our terms & conditions.
                    </label>
                  </div> */}

                  <div className="register-package my-5">
                    <h5 className="text-center">Choose Package</h5>
                    {packages &&
                      packages?.map((item, index) => {
                        return (
                          <div
                            className={`pack ${
                              item.packageName ===
                                selectedPackage?.packageName && "active"
                            }`}
                            onClick={() => setSelectedPackage(item)}
                          >
                            <span>{item?.packageName}</span>
                            <span>Rs. {item?.price}</span>
                          </div>
                        );
                      })}
                  </div>

                  <div className="payment-method">
                    {/* ASKING USER ABOUT REFER CODE  */}
                    <Modal
                      title="Do you have a Referral Code?"
                      visible={visible}
                      onOk={() => handleOk("asking")}
                      onCancel={() => handleCancel("asking")}
                    >
                      <p>
                        Kya Ap ka contact kisi aise bandy se hai jis ka referral
                        code use kar k ap account bana sakein?
                      </p>
                    </Modal>
                    {/* CANT REGISTER WITHOUT REFER CODE MODAL  */}
                    <Modal
                      title="Don't Have a Referral Code"
                      visible={cantRegisterModal}
                      onOk={() => handleOk("warning")}
                      onCancel={() => handleCancel("warning")}
                    >
                      <p>
                        * Referral Code k bina ap ka account nahi bany ga. *
                        <br />
                        Agar Ap ka contact kisi aise bandy se hai jis ne ap ko
                        SkillRiseUp ki details di hein to kindly usko msg kariye
                        usi ka referral code use kar k ap account bana sakein
                        gy.
                        <br />* Agar ap k pass referral link ya code dene wala
                        koi nahi hai to payment na karein.
                        <br /> * Kisi aise bandy ko talash kariye jis ka
                        referral code use kar k ap account bana saky. Thank You
                      </p>
                    </Modal>
                    {/* BANK DETAILS MODAL  */}
                    <Modal
                      title="Official Account Numbers"
                      visible={bankModal}
                      onOk={() => handleOk("bank")}
                      onCancel={() => handleCancel("bank")}
                    >
                      <h4 className="text-danger">Note</h4>
                      <p>
                        * Agar ap k pass referral link ya code dene wala koi
                        nahi hai to payment na karein.
                      </p>
                      <p>
                        * Kisi aise bandy ko talash kariye jis ka referral code
                        use kar k ap account bana saky.
                      </p>
                      <p>
                        * Fees pay karny k bad screenshot us bandy ko bhejiye
                        jis ny apko SkillRiseUp ki details di hein.
                      </p>
                      <p>
                        * Nichy diye huy numbers k ilawa kisi or number py
                        payment na karein.
                      </p>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Bank</th>
                            <th>Account Name</th>
                            <th>Account No</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Meezan Bank</td>
                            <td>Muhammad Hassan</td>
                            <td>01530105001385</td>
                          </tr>
                          <tr>
                            <td>Easypaisa</td>
                            <td>Hammad Rafeeq</td>
                            <td>03222004436</td>
                          </tr>
                          <tr>
                            <td>Jazzcash</td>
                            <td>Hammad Rafiq</td>
                            <td>03222004436</td>
                          </tr>
                        </tbody>
                      </table>
                    </Modal>
                    <div className="row">
                      <div className="col-12">
                        <button
                          onClick={showModal}
                          className="w-100 button text-dark"
                        >
                          Payment Method
                        </button>
                      </div>
                      <div className="col-12" style={{marginTop:'20px'}}>
                        <input
                          onChange={(e) => setTxnId(e.target.value)}
                          value={txnId}
                          type="text"
                          className="form-control py-2 tnx-input"
                          placeholder="Enter transaction Id"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label">
                      Agree with{" "}
                      <Link to="/terms-and-conditions" target="_blank">
                        terms and conditions *
                      </Link>
                    </label>
                    <label className="form-check-label text-danger">
                      {message4}
                    </label>
                  </div>
                  <button
                    disabled={!register}
                    type="submit"
                    className={`reg-btn ${!register && "active"}`}
                  >
                    {loading ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="ms-2">Loading...</span>
                      </div>
                    ) : (
                      "Register"
                    )}
                  </button>
                </form>
                <div className="login-with text-center m-auto w-100">
                  <span className="text-center my-3 d-flex align-items-center justify-content-center">
                    <span className="mx-3 hr-line"></span>
                    <span className="">or</span>
                    <span className="mx-3 hr-line"></span>
                  </span>
                  <Link to="/login" type="submit">
                    <span>Already Registered? Login here</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Header>
  );
};

export default Register;
