import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import Header from "../Header/Header";
import axios from "axios";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoneIcon from "@mui/icons-material/Done";
import "./allcourse.css";

const AllCourse = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [packages, setPackages] = useState(null);

  async function getAllPackages() {
    try {
      const res = await axios.get("/api/package/get-all-package");
      if (res.data.success) {
        setPackages(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllPackages();
  }, []);

  return (
    <React.Fragment>
      <Header>
        <div className="course-cont how-it-works">
          <h2 className="text-lg-center mb-4">
            Our
            <span> Courses</span>
          </h2>
          <div className="row">
            {packages?.map((item, index) => {
              return (
                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                  <div className="package">
                    <img src={item?.image} alt="" />
                    <h5>{item?.title}</h5>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>
                        <MenuBookIcon className="icon me-1" />
                        {item?.courseName?.split(",").length} Courses
                      </span>
                      <span>
                        <AccessTimeIcon className="icon me-1" />
                        0d 5hr 31min
                      </span>
                    </div>
                    <ul>
                      <li>
                        <DoneIcon className="icon me-2" />
                        Live Support
                      </li>
                      <li>
                        <DoneIcon className="icon me-2" />
                        10k+ Students Enrolled
                      </li>
                      <li>
                        <DoneIcon className="icon me-2" />
                        Certificate
                      </li>
                    </ul>
                    <button
                      onClick={() =>
                        navigate(`/course-detail/${item?.packageName}`)
                      }
                      className="button"
                    >
                      Get Started Now
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Header>
    </React.Fragment>
  );
};

export default AllCourse;
