import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./ActNow.css";

const ActNow = () => {
  const navigate = useNavigate();
  return (
    <div className="act-now-container how-it-works">
      <h2 className="text-lg-center mb-4">
        The
        <span> Choice</span> is Yours.
      </h2>
      <p>The Matrix wants you poor, weak, and alone.</p>
      <p>
        We have opened up a mass portal to give you the tools to escape
        modern-day slavery.
      </p>
      <p>
        <b>Do you wish to Escape?</b>
      </p>
      {/* <div className="text-center">
        <iframe
          style={{ borderRadius: "20px", margin: "30px 0" }}
          width="600"
          height="350"
          src="https://www.youtube.com/embed/wEz8M1fmFTY?si=ryQIDjbr1LQ60yh2"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div> */}
      <Link style={{ textDecoration: "none" }} to="/register">
        <button>Join Now</button>
      </Link>
    </div>
  );
};

export default ActNow;
