import React from "react";
import Header from "../components/Header/Header";

const Privacy = () => {
  return (
    <Header>
      <div className="container p-5">
        <h4>
          <b>Privacy Policy for Skill Rise Up Course Website</b>
        </h4>
        <p>
          Welcome to Skill Rise Up! This Privacy Policy outlines how we collect,
          use, disclose, and safeguard your personal information when you use
          our website. By accessing or using the Skill Rise Up Course Website
          ("www.skillriseup.com"), you consent to the practices described in
          this policy.
        </p>
        <b>1.Information We Collect</b>
        <br />- Personal Information: When you create an account, we may collect
        your name, email address, and payment information for account
        activation. <br />- Referral Program Information: If you participate in
        our referral program, we may collect information related to your
        referrals and rewards. <br />- Usage Information: We may collect
        information about how you use the Website, including pages visited,
        links clicked, and time spent on the site. <br />- Device Information:
        We may collect information about the device you use to access the
        Website, including device type, operating system, and browser.
        <br />
        <br />
        <b>2.How We Use Your Information</b>
        <br /> - Account Management: We use your personal information to manage
        your account, process payments, and provide access to course content.
        <br />
        - Referral Program: If you participate in our referral program, we use
        the information to track referrals and distribute rewards.
        <br /> - Communication: We may use your contact information to send you
        updates, newsletters, and promotional materials. <br />- Analytics: We
        use usage and device information to analyze website performance and
        improve user experience.
        <br />
        <br />
        <b>3.Sharing of Information</b>
        <br /> - We do not sell, trade, or rent your personal information to
        third parties. However, we may share information with trusted service
        providers who assist us in operating our website and conducting our
        business. <br />- We may also disclose your information when required by
        law or to protect our rights, privacy, safety, or property.
        <br />
        <br />
        <b>4.Security</b>
        <br /> - We implement industry-standard security measures to protect
        your personal information from unauthorized access, disclosure,
        alteration, and destruction.
        <br />
        <br />
        <b>5.Your Choices</b>
        <br /> - You can update or correct your account information by logging
        into your account settings. <br />
        -You may choose to opt out of receiving promotional emails by following
        the instructions provided in the email.
        <br />
        <br />
        <b>6.Children's Privacy</b>
        <br /> - The Website is not intended for individuals under the age of
        18. We do not knowingly collect personal information from minors.
        <br />
        <br />
        <b>7.Third-Party Links</b>
        <br />- Our website may contain links to third-party websites. We are
        not responsible for the privacy practices or content of these sites.
        Please review the privacy policies of any third-party sites you visit.
        <br />
        <br />
        <b>8.Changes to This Privacy Policy</b>
        <br /> - We may update this Privacy Policy to reflect changes in our
        practices or for other operational, legal, or regulatory reasons. Any
        changes will be effective immediately upon posting on the Website.
        <br />
        <br />
        <b>9.Contact Us</b>
        <br /> - If you have any questions or concerns about this Privacy
        Policy, please contact us at [info@Skill Rise Up.com].
        <br />
        <br />
        By using the Skill Rise Up Website, you acknowledge that you have read,
        understood, and agree to the practices described in this Privacy Policy.
      </div>
    </Header>
  );
};

export default Privacy;
