import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminHeader from "../Header/AdminHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AdminLayout from "../layout/AdminLayout";

const AdminCourse = () => {
  const params = useParams();
  const paramsid = params.id;
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState([{ title: "", link: "" }]);

  const addLink = () => {
    setLinks([...links, { title: "", link: "" }]);
  };

  const removeLink = (index) => {
    const newLinks = [...links];
    newLinks.splice(index, 1);
    setLinks(newLinks);
  };

  const handleLinkChange = (index, field, value) => {
    const newLinks = [...links];
    newLinks[index][field] = value;
    setLinks(newLinks);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios({
      method: "POST",
      data: {
        id: paramsid,
        links: links,
      },
      withCredentials: true,
      url: `api/course/update-course`,
    })
      .then((res) => {
        setLoading(false);
        setCourse(res.data.data);
        console.log(res.data.data);
        setLinks([...res.data.data.videoLinks]);
        navigate("/admin-course");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getCourse = async (id) => {
    axios({
      method: "POST",
      data: {
        id: id,
      },
      withCredentials: true,
      url: `api/course/get-course`,
    })
      .then((res) => {
        setCourse(res.data.data);
        setLinks([...res.data.data.videoLinks]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (paramsid) {
      getCourse(paramsid);
    }
    // eslint-disable-next-line
  }, [window.location.search]);

  const extractVideoId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match ? match[1] : null;
  };

  return (
    <AdminHeader>
      <AdminLayout>
        <div className="welcome-container">
          <div className="username d-flex justify-content-between">
            <span className="d-flex jsutify-content-center align-items-center">
              <ArrowBackIcon
                className="me-2 cursor-pointer"
                onClick={() => {
                  navigate("/admin-course");
                }}
              />
              <b>{course?.courseName?.toUpperCase()}</b>
            </span>
            <span>
              <b>5</b>
            </span>
          </div>
        </div>
        <div className="mt-4 user-course-container">
          <form className="w-100 text-end" onSubmit={handleSubmit}>
            {links?.map((linkObj, index) => (
              <div key={index} className="link-container d-flex">
                <p className="m-0 p-0 my-2 bg-dark text-white d-flex justify-content-center align-items-center px-2">
                  {index + 1}
                </p>
                <input
                  className="form-control m-2"
                  type="text"
                  placeholder="Enter Youtube Title"
                  value={linkObj?.title}
                  onChange={(e) =>
                    handleLinkChange(index, "title", e.target.value)
                  }
                />
                <input
                  className="form-control m-2"
                  type="text"
                  placeholder="Enter Youtube Link"
                  value={linkObj?.link}
                  onChange={(e) =>
                    handleLinkChange(index, "link", e.target.value)
                  }
                />
                <button
                  className="my-2 btn btn-danger btn-sm"
                  type="button"
                  onClick={() => removeLink(index)}
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              className="m-2 btn btn-dark"
              type="button"
              onClick={addLink}
            >
              Add More
            </button>
            <button className="m-2 btn btn-success  me-0 px-4" type="submit">
              {loading ? "Loading.." : "Submit"}
            </button>
          </form>
        </div>
        <div className="mt-4 user-course-container d-flex justify-content-center align-items-center">
          {links.map((linkObj, index) => (
            <div key={index} className="embedded-video card ">
              <h5 className="p-2" style={{ width: "355px" }}>
                {linkObj.title}
              </h5>
              <iframe
                width="385"
                height="218"
                src={`https://www.youtube.com/embed/${extractVideoId(
                  linkObj.link
                )}`}
                title={linkObj.title}
                allowFullScreen
              ></iframe>
            </div>
          ))}
        </div>
      </AdminLayout>
    </AdminHeader>
  );
};

export default AdminCourse;
