import React from "react";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import "./HowItWorks.css";

const HowItWorks = () => {
  return (
    <div className="py-5 container-fluid how-it-works">
      <h2 className="text-lg-center mb-4">
        How
        <span> Skill Rise Up</span> Works?
      </h2>
      <div className="row">
        <div className="px-3 px-lg-5 text-center col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
          <HowToRegIcon className="icon" style={{ fontSize: "70px" }} />
          <h5 className="fw-bold my-2">Sign Up</h5>
          <h6 className="mb-4">Be a part of Skill Rise Up</h6>
          <p className="text-muted">
            Unleash the power of your passion in your profession! Experience
            career growth through cutting-edge learning, collaborative ventures,
            and a dynamic community. Come together with us to redefine the
            future of work!
          </p>
        </div>
        <div className="px-3 px-lg-5 text-center col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
          <AutoStoriesIcon className="icon" style={{ fontSize: "70px" }} />
          <h5 className="fw-bold my-2">Get Courses</h5>
          <h6 className="mb-4">Get the premium access</h6>
          <p className="text-muted">
            Take your career to new heights with Premium Access: dive into
            unlimited content, engage in advanced workshops, receive
            personalized coaching, and enjoy priority support. Unlock exclusive
            benefits and fast-track your growth with Skill Rise Up's premium
            membership.
          </p>
        </div>
        <div className="px-3 px-lg-5 text-center col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
          <LocalAtmIcon className="icon" style={{ fontSize: "70px" }} />
          <h5 className="fw-bold my-2">Earn Money</h5>
          <h6 className="mb-4">Withdraw Instant Money</h6>
          <p className="text-muted">
            Turn your virtual coins into cold, hard cash effortlessly with Skill
            Rise Up. Our simple conversion system seamlessly transforms your
            digital earnings into tangible rewards. Make your virtual success a
            reality today!
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
