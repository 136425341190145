import React from "react";
import "./Faq.css";
import IMAGES from "../../img";
import { useNavigate } from "react-router-dom";

const Faq = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="faq-container">
        <h2 className="faq">Frequently Asked Question</h2>
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                What is Skill Rise Up?
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Skill Rise Up is an online platform where students can learn and
                earn at the same time.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                What opportunities does Skill Rise Up provides?
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Skill Rise Up provides various opportunities to the students.
                Students will get the benefits of premium courses and for each
                invite they will earn coins which can be redeemed into real
                cash. Meanwhile they can earn while learning at our platform.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                How much money I can earn?
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                There's no limit to earn money on our platform. It depends on
                how much students you can connect to our platform.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
              >
                Can I withdraw my money into real cash?
              </button>
            </h2>
            <div
              id="flush-collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                A big Yes! You can withdraw your money and also you can redeemed
                them to purchase a new course and add a new skill in your list.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-support">
        <div className="faq">
          <span>Skill Rise Up</span> Learner Support
        </div>
        <div className="learner-img">
          <img src={IMAGES.learner} alt="" />
        </div>
        <div className="learner-content">
          <h5 className="text-center d-flex justify-content-center align-items-center">
            {/* <LocalPhoneIcon className="icon" /> +91 7758007845 */}
            <a
              className="text-center m-3 d-flex flex-column justify-content-center align-items-center"
              href=""
              style={{ textDecoration: "none", color: "black" }}
            >
              <i
                className="fa-solid fa-envelope mb-2 text-dark "
                style={{ fontSize: "35px" }}
              ></i>
              <p style={{ textDecoration: "underline" }}>email</p>
            </a>
            <a
              className="text-center m-3 d-flex flex-column justify-content-center align-items-center"
              href="https://wa.me/923709187936"
              style={{ textDecoration: "none", color: "black" }}
            >
              <i
                className="fa-brands fa-whatsapp mb-2 text-dark"
                style={{ fontSize: "35px" }}
              ></i>
              <p style={{ textDecoration: "underline" }}>WhatsApp</p>
            </a>
          </h5>
          <p className="text-center">
            <small>
              (We are available on Monday-Sunday from 9:30 AM to 6:00 PM)
            </small>
          </p>
        </div>
        <div className="disclaimer shadow text-center">
          <h6>
            <h3
              className=" text-center m-auto "
              style={{ width: "100%", fontSize: "24px" }}
            >
              Get in touch to find out more about our service
            </h3>
          </h6>
          <span>
            <button
              className="py-2 px-3 rounded-pill border-0 bg-dark text-white text-center mt-3"
              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact Us
            </button>
          </span>
        </div>
        <div className="disclaimer shadow">
          <h6>
            <b>Disclaimer:</b>
          </h6>
          <span>
            <small>
              Skill Rise Up is not responsible for payment made against our
              products to anyone other than our website or through affiliate
              link.
            </small>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Faq;
