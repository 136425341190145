import React from "react";
import "./WeAre.css";
import IMAGES from "../../img";

const WeAre = () => {
  return (
    <div className="container we-are text-center mb-5 m-auto">
      <div className="row">
        <div className="we-logo col-12 col-sm-12 col-md-6 col-lg-6">
          <h1 className="text-white">
            <b>Skill Rise Up</b>
          </h1>
        </div>
        <div className="px-4 d-block d-md-none d-lg-none">
          <hr className="m-0 p-0" />
        </div>
        <div className="we-content col-12 col-sm-12 col-md-6 col-lg-6">
          <h3>
            <b>
              Develop Your <span className="text-primary ">Skills</span>
            </b>
          </h3>
          <h3>
            <b>
              Pursue Your <span className="text-primary ">Passion</span>
            </b>
          </h3>
          <h3>
            <b>
              Make A <span className="text-primary ">Difference</span>
            </b>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default WeAre;
