import IMAGES from "../../img";

const course = [
  {
    name: "Wordpress Course",
    category: "Web Development",
    profile: IMAGES?.aashiransari,
    instructor: "Ashir Ansari",
    rating: "4.6",
    level: "Basic to Advance",
    lessions: "12",
    image: `${IMAGES.wpCourse}`,
    overvieww: [
      {
        heading: "Getting Started with WordPress",
        desc: "An Introduction video to let you understand.",
      },
      {
        heading: "Hosting & Domain Setup",
        desc: "Learning about hosting and domain. From where we can buy.",
      },
      {
        heading: "Hosting & Domain Connectivity",
        desc: "We Will learn how to connect the hosting and domain.",
      },
      {
        heading: "Wordpress Installation",
        desc: "We we learn about wordpress installation in a website,",
      },
      {
        heading: "Wordpress Basic Settings",
        desc: "We will do basic settings in our wordpress website.",
      },
      {
        heading: "Installing Themes",
        desc: "We will learn how to install wordpress themes.",
      },
      {
        heading: "Elementor",
        desc: "We will learn about elementor page builder.",
      },
      {
        heading: "Portfolio Website",
        desc: "We will create a portfolio website using wordpress",
      },
    ],
    whatLearn: [
      {
        desc: "How to buy Hosting & Domain",
      },
      {
        desc: "How to connect Hosting & Domain",
      },
      {
        desc: "Wordpress Installation",
      },
      {
        desc: "Wordpress Basic Settings",
      },
      {
        desc: "How to Install Themes",
      },
      {
        desc: "Learning Elementor Page Builder",
      },
      {
        desc: "Creating a Portfolio Website from scratch",
      },
    ],
    requirements: ["Internet Connection", "Laptop"],
    skillsCovered: ["Hosting", "Domain", "Wordpress", "Elementor"],
  },
  {
    name: "The Complete HTML tutorial",
    profile: IMAGES?.profile1,
    instructor: "Aniket Singh",
    rating: "4.6",
    category: "Web Development",
    level: "Basic to Advance",
    lessions: "12",
    image: `${IMAGES.htmlCourse}`,
    overvieww: [
      {
        heading: "Introduction to HTML",
        desc: "Understanding the role of HTML in web development. Setting up basic Html file, Structure of an HTML document, Elements, Tags, Attribute",
      },
      {
        heading: "HTML Document Structure",
        desc: "Headings, paragraphs, and line breaks, Lists (ordered and unordered), Links and anchors, Images and multimedia embedding, Semantic HTML5 elements",
      },
      {
        heading: "Forms and User Input",
        desc: "Creating forms and input fields, Radio buttons, checkboxes, and select menus, Form submission and handling, Input validation and error handling",
      },
      {
        heading: "Tables and Data",
        desc: "Creating tables for data representation, Formatting and styling tables, Table headers, rows, and columns, Embedding multimedia within tables",
      },
      {
        heading: "Semantic HTML5 Elements",
        desc: "Familiarize yourself with modern HTML5 elements that provide meaning and structure to content.",
      },
      {
        heading: "HTML Entities and Special Characters",
        desc: "Understand how to represent special characters and symbols in HTML.",
      },
      {
        heading: "Comments in HTML",
        desc: "Learn how to add comments to your HTML code for documentation or explanatory purposes.",
      },
    ],
    whatLearn: [
      { desc: "Introduction to HTML" },
      { desc: "Basic HTML Structure" },
      { desc: "HTML Elements and Tags" },
      { desc: "Headings and Paragraphs" },
      { desc: "Lists (Ordered and Unordered)" },
      { desc: "Links and Anchors" },
      { desc: "Images and Multimedia" },
      { desc: "Forms and Input Elements" },
      { desc: "Tables and Data Representation" },
      { desc: "Semantic HTML5 Elements" },
      { desc: "HTML Entities and Special Characters" },
      { desc: "Comments in HTML" },
      { desc: "HTML Validation and Best Practices" },
      { desc: "Creating Navigation Menus" },
      { desc: "Embedding Audio and Video" },
      { desc: "HTML5 Canvas and SVG" },
      { desc: "Meta Tags and SEO Optimization" },
      { desc: "Responsive Design with HTML and CSS" },
      { desc: "HTML Accessibility Best Practices" },
      { desc: "HTML Boilerplates and Starter Templates" },
      { desc: "HTML for Mobile Devices (Viewport, Meta Tags)" },
      { desc: "Embedding Maps and Location Data" },
      { desc: "Creating Forms for User Input" },
      { desc: "Data Validation in Forms" },
      { desc: "Embedding Social Media Widgets" },
      { desc: "Creating Dropdown Menus" },
      { desc: "HTML Forms for E-commerce" },
      { desc: "Embedding Fonts and Icons" },
      {
        desc: "Understand how to include custom fonts and icon sets in your webpage.",
      },
    ],
    requirements: ["Laptop or PC", "VS CODE"],
    skillsCovered: ["Html", "Folder Structure", "SEO", "HTML Elements"],
  },
  {
    name: "Digital Marketing Course",
    profile: IMAGES?.profile1,
    instructor: "Aniket Singh",
    rating: "4.6",
    category: "Digital Marketing",
    level: "Basic to Advance",
    lessions: "12",
    image: `${IMAGES.digitalMarketingCourse}`,
    overvieww: [
      {
        heading: "Course Overview",
        desc: "Welcome to the Comprehensive Digital Marketing Mastery course! In this dynamic program, you will dive deep into the world of digital marketing, equipping you with the skills and strategies needed to thrive in the rapidly evolving online landscape. Whether you're a marketing professional seeking to enhance your digital skills or an entrepreneur aiming to build a robust online presence, this course is designed to empower you with the knowledge to excel in the digital marketing realm.",
      },
      {
        heading: "Course Description",
        desc: "Digital marketing has become a critical component of modern business strategies. This course provides a comprehensive exploration of key digital marketing channels, tools, and techniques, enabling you to create and execute effective online marketing campaigns. From search engine optimization (SEO) to social media marketing, content strategy, email marketing, and beyond, you'll gain expertise in leveraging various platforms to reach and engage your target audience.",
      },
    ],
    whatLearn: [
      { desc: "Introduction to Digital Marketing" },
      { desc: "Website Strategy and User Experience" },
      { desc: "Search Engine Optimization (SEO)" },
      { desc: "Pay-Per-Click (PPC) Advertising" },
      { desc: "Social Media Marketing" },
      { desc: "Content Marketing and Strategy" },
      { desc: "Email Marketing" },
      { desc: "Influencer Marketing and Partnerships" },
      { desc: "Analytics and Data-driven Decision Making" },
      { desc: " Digital Marketing Campaign Planning and Execution" },
      { desc: " Legal and Ethical Considerations in Digital Marketing" },
      { desc: " Emerging Trends and Future of Digital Marketing" },
    ],
    requirements: [
      "Curiosity and Willingness to Learn",
      "Internet Access and a Computer",
      "Basic Math and Analytical Skills",
      "Good Communication Skills",
    ],
    skillsCovered: [
      "SEO",
      "Pay-Per-Click (PPC) Advertising",
      "Social Media Marketing",
      "Content Marketing",
      "Email Marketing",
      "Social Media Advertising",
    ],
  },
];

export default course;
