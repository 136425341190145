import React from "react";
import "./Text.css";

const Text = () => {
  return (
    <>
      <div className="container text-container border p-3 pt-5 bg-white">
        <h3 className="mb-4" style={{ padding: "0 12px" }}>
          About Us
        </h3>
        <p className="text-muted container">
          Welcome to The Skill Rise Up, where we believe in empowering
          individuals to unleash their fullest potential and lead extraordinary
          lives. At The Skill Rise Up, we understand that true success
          encompasses various facets of life, extending beyond the confines of a
          traditional education. That's why we've curated a comprehensive
          self-development course that transcends boundaries and equips you with
          the skills and mindset needed to navigate the complexities of the
          modern world.
        </p>
      </div>
      <div className="container text-container border shadow p-3 pt-5 mt-4 bg-white">
        <h3 className="mb-4" style={{ padding: "0 12px" }}>
          Our Mission
        </h3>
        <p className="text-muted container">
          At the core of The Skill Rise Up is a mission to foster personal and
          professional growth by providing a transformative learning experience.
          We are dedicated to cultivating a community of empowered individuals
          who strive for excellence in every aspect of their lives.{" "}
        </p>
      </div>
      <div className="container text-container border shadow p-3 pt-5 mt-4 bg-white">
        <h3 className="mb-4" style={{ padding: "0 12px" }}>
          What Sets Us Apart
        </h3>
        <p className="text-muted container">
          Holistic Approach: Our self-development course is designed to address
          a broad spectrum of topics, ensuring a well-rounded transformation.
          From mastering financial literacy to honing effective speaking skills,
          managing time efficiently, and building valuable assets, we cover it
          all.{" "}
        </p>
        <p className="text-muted container">
          Expert Guidance: Our team of seasoned professionals and industry
          experts bring a wealth of knowledge to the table. Benefit from their
          vast experience and gain insights that go beyond textbook theories.
        </p>
        <p className="text-muted container">
          Interactive Learning: We believe in engaging, interactive learning
          experiences. Our courses are designed to be dynamic, allowing you to
          actively participate and apply the concepts learned in real-life
          scenarios.
        </p>
        <p className="text-muted container">
          Community Support: Join a community of like-minded individuals on the
          same journey of self-discovery. Share experiences, exchange ideas, and
          celebrate victories together. The Skill Rise Up is more than just a
          course; it's a supportive network.
        </p>
        <p className="text-muted container">
          Adaptability: The world is constantly evolving, and so are we. Our
          courses are regularly updated to reflect the latest trends and
          advancements in self-development, ensuring that you stay ahead of the
          curve.
        </p>
        <p className="text-muted container">
          Embark on a transformative journey with The Skill Rise Up and unlock
          the doors to a brighter, more fulfilling future. Invest in yourself,
          embrace growth, and join a community of individuals dedicated to
          becoming the best versions of themselves. Your journey to elite living
          starts here. Welcome to The Skill Rise Up – Where Excellence Meets
          Development.
        </p>
      </div>
      <div className="container text-container border shadow p-3 pt-4 mt-4 bg-white">
        <h3 className="mb-4" style={{ padding: "0 12px" }}>
          Contact Us
        </h3>
        <p className="text-muted container">
          If you have any questions or need assistance, please don't hesitate to
          contact our friendly support team at{" "}
          <strong>contact@theeliteshub.com</strong> . We are here to help you
          succeed.
          <br />
          Thank you for choosing SkillRiseUp as your learning partner. We look
          forward to helping you achieve your educational and financial goals.
        </p>
      </div>
    </>
  );
};

export default Text;
