import React, { useState } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import axios from "axios";
import { SetUser } from "../redux/features/userSlice";
import { useDispatch } from "react-redux";

const WithdrawAmountModal = ({ user, setAlert2, setState, alert2 }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [withdrawMode, setWithdrawMode] = useState("");
  const [coinAmount, setCoinAmount] = useState(0);
  const [loading2, setLoading2] = useState(false);

  const withDrawAmount = () => {
    if (coinAmount > user?.amount) {
      return setMessage(
        "You don't have enough money in your wallet to withdraw."
      );
    }
    if (coinAmount === 0) {
      return setMessage("Please Add Withdraw Amount");
    }
    if (!user?.payment[withdrawMode]) {
      return setMessage(
        `Please Update Your ${withdrawMode} Payment Method First`
      );
    }

    setLoading2(true);
    axios({
      method: "POST",
      data: {
        id: user?._id,
        amount: coinAmount,
        mode: withdrawMode,
      },
      withCredentials: true,
      url: `api/withdraw/add-withdraw`,
    })
      .then((res) => {
        if (res.data.success) {
          setTimeout(() => {
            setAlert2(true);
            setLoading2(false);
            setCoinAmount(0);
            setWithdrawMode("");
            setMessage("");
            dispatch(SetUser(res.data.data));
          }, 1500);
        }
      })
      .catch((err) => {
        setLoading2(false);
        console.log(err);
      });
  };

  return (
    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
      <div className="modal-content rounded-0 pt-2">
        <div className="modal-body">
          <h4 className="mb-2">Withdraw Money</h4>
          <hr className="m-0" />
          {user?.amount <= 0 ? (
            <p className="mt-4">
              You can't withdraw right now because you currently have{" "}
              {user?.amount} rupees.
            </p>
          ) : (
            <div>
              <form action="">
                <div className="col-12">
                  <label htmlFor="" className="mt-3 mb-2">
                    Withdrawal Amount
                  </label>
                  <input
                    className="w-100 px-3 py-2"
                    type="number"
                    name="coinAmount"
                    placeholder="How much money to withdraw"
                    onChange={(e) => {
                      setCoinAmount(e.target.value);
                    }}
                    value={coinAmount}
                    required
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="" className="mt-3 mb-2">
                    Select Payment Mode
                  </label>
                  <select
                    className="w-100 px-3 py-2 form-control"
                    name="coinAmount"
                    onChange={(e) => {
                      setWithdrawMode(e.target.value);
                    }}
                    value={withdrawMode}
                    required
                  >
                    <option>Select</option>
                    <option value={"account"}>Bank Account</option>
                    <option value={"easyPaisaName"}>Easy Paisa</option>
                    <option value={"jazzCashaName"}>Jazz Cash</option>
                  </select>
                </div>
              </form>
            </div>
          )}
          {alert2 && (
            <p
              className="text-center m-0 p-0 mt-4 text-success"
              style={{ fontSize: "14px" }}
            >
              <VerifiedIcon className="mb-2 fs-1 text-success" />
              <br />
              Withdrawal has been successfully done, money will be credited to
              your account in some time. <br />
              <h6 className="m-0 mt-3">
                Your Remaining Balance is Rs. {user?.amount}
              </h6>
            </p>
          )}
          {message !== "" && (
            <p
              className="m-0 p-0 mt-4 text-danger"
              style={{ fontSize: "14px" }}
            >
              <br />
              {message}
            </p>
          )}
        </div>
        <div className="modal-footer">
          <div>
            <button
              type="button"
              className="btn btn-secondary rounded-0 me-2"
              data-bs-dismiss="modal"
              onClick={() => {
                setState(false);
                setCoinAmount(0);
                setAlert2(false);
                setWithdrawMode("");
                setMessage("");
              }}
            >
              {!alert2 ? "Cancel" : "Close"}
            </button>
            {user?.amount <= 0 ? null : (
              <button
                type="button"
                className="btn btn-warning rounded-0"
                onClick={withDrawAmount}
              >
                {loading2 ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="ms-2">Loading...</span>
                  </div>
                ) : (
                  "Withdraw"
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawAmountModal;
