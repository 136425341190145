import React, { useEffect, useState } from "react";
import axios from "axios";
import AdminLayout from "../layout/AdminLayout";
import AdminHeader from "../Header/AdminHeader";
import { message, Modal } from "antd";

function AdminWithdraw() {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const status = [
    { value: "1", name: "Pending" },
    { value: "2", name: "In Progress" },
    { value: "3", name: "Successfull" },
    { value: "4", name: "On Hold" },
  ];
  const color = ["bg-secondary", "bg-warning", "bg-success", "bg-danger"];

  const [allWithdrawals, setAllWithdrawals] = useState(null);
  const [filterData, setFilterData] = useState(null);

  const handleConfirm = (withdrawalId, status) => {
    const confirmation = window.confirm(
      "Are you sure you want to update the withdrawal?"
    );

    if (confirmation) {
      updateWithdrawal(withdrawalId, status);
    }
  };

  const handleStatusSelect = (e) => {
    const value = e.target.value;
    const filterArray = allWithdrawals?.filter((data) => {
      return data.status === value;
    });
    if (value === "0") {
      setFilterData(allWithdrawals);
    } else {
      setFilterData(filterArray);
    }
  };

  const getAllWithdrawals = async () => {
    axios({
      method: "get",
      withCredentials: true,
      url: `api/admin/get-all-withdrawals`,
    })
      .then((res) => {
        setAllWithdrawals(res?.data?.data);
        setFilterData(res?.data?.data?.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateWithdrawal = async (withdrawalId, newStatus) => {
    try {
      const response = await axios({
        method: "post",
        withCredentials: true,
        url: "api/admin/update-withdrawal",
        data: {
          id: withdrawalId,
          newStatus: newStatus,
        },
      });

      if (response?.data?.success) {
        getAllWithdrawals();
      } else {
        console.error("Failed to update withdrawal status.");
      }
    } catch (error) {
      console.error("Error updating withdrawal status:", error);
    }
  };

  useEffect(() => {
    getAllWithdrawals();
    // eslint-disable-next-line
  }, []);
  return (
    <AdminHeader>
      <AdminLayout>
        <div className="tab">
          <div className="welcome-container">
            <div className="username d-flex justify-content-between">
              <span>
                <b>All</b> Withdrawals
              </span>
              <span>
                Total Withdrawals: <b>{allWithdrawals?.length}</b>
              </span>
              <span>
                <select name="" id="" onChange={handleStatusSelect}>
                  <option value="0">All Status</option>
                  {status?.map((elm) => {
                    return <option value={elm.value}>{elm.name}</option>;
                  })}
                </select>
              </span>
            </div>
          </div>
          <div className="w-100 mt-4 " style={{ overflow: "auto" }}>
            <table className="w-100">
              <thead>
                <tr>
                  <th scope="col" className="">
                    #
                  </th>
                  <th scope="col" className="">
                    Name
                  </th>
                  <th scope="col" className="">
                    Email
                  </th>
                  <th scope="col" className="">
                    Number
                  </th>
                  <th scope="col" className="">
                    Bank Acc Name
                  </th>
                  <th scope="col" className="">
                    Bank Acc Number
                  </th>
                  <th scope="col" className="">
                    IFSC Code
                  </th>
                  <th scope="col" className="">
                    Req Amount
                  </th>
                  <th scope="col" className="">
                    Status
                  </th>
                  <th scope="col" className="">
                    Req Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {filterData?.map((data, index) => {
                  const createdAtDate = new Date(data?.createdAt);
                  const day = createdAtDate.getDate();
                  const month = createdAtDate.getMonth();
                  const year = createdAtDate.getFullYear();
                  const user = data?.id;
                  return (
                    <tr key={data?._id} className="border">
                      <td className="px-2 fs-5">
                        <small>{index + 1}</small>
                      </td>
                      <td
                      // data-bs-toggle="modal"
                      // data-bs-target="#exampleModal"
                      // className="cursor-pointer"
                      >
                        <small>{user?.name}</small>
                      </td>
                      <td className="text-dark px-2 fs-6">
                        <small>{user?.email}</small>
                      </td>
                      <td
                        className={`text-start text-nowrap px-3 text-center fs-6`}
                      >
                        <small>{user?.number}</small>
                      </td>
                      <td>
                        {data?.mode === "account"
                          ? user?.payment?.name
                          : data?.mode === "easyPaisaName"
                          ? user?.payment?.easyPaisaName
                          : user?.payment?.jazzCashaName}
                      </td>
                      <td>
                        {data?.mode === "account"
                          ? user?.payment?.account
                          : data?.mode === "easyPaisaName"
                          ? user?.payment?.easyPaisaAccount
                          : user?.payment?.jazzCashaAccount}
                      </td>
                      <td>
                        {data?.mode === "account"
                          ? user?.payment?.ifsc
                          : data?.mode === "easyPaisaName"
                          ? user?.payment?.easyPaisaIfsc
                          : user?.payment?.jazzCashaIfsc}
                      </td>
                      <td className="text-start fs-6">
                        <small>{data?.amount}</small>
                      </td>
                      <td className="text-start fs-6">
                        {data.status === "3" ? (
                          <p className="text-success">Successfull</p>
                        ) : (
                          <select
                            className={`${color[data?.status - 1]} text-white `}
                            value={data?.status}
                            onChange={(e) => {
                              handleConfirm(data?._id, e.target.value);
                            }}
                          >
                            <option value="">
                              <small>
                                {status?.map((elm) => {
                                  if (elm.value === data.status) {
                                    return elm.name;
                                  }
                                })}
                              </small>
                            </option>
                            {status?.map((elm) => {
                              if (elm.value !== data.status) {
                                return (
                                  <option value={elm.value}>{elm.name}</option>
                                );
                              }
                            })}
                          </select>
                        )}
                      </td>
                      <td className="text-start">
                        <small>
                          {day + " " + monthNames[month] + " " + year}
                        </small>
                      </td>
                      <div
                        class="modal fade"
                        id="exampleModal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel">
                                Bank Details
                              </h5>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body">
                              <table className="table">
                                <thead>
                                  {data?.mode === "account" && (
                                    <>
                                      <tr>
                                        <td>Bank Name</td>
                                        <td>{user?.payment?.name}</td>
                                      </tr>
                                      <tr>
                                        <td>Account Number</td>
                                        <td>{user?.payment?.account}</td>
                                      </tr>
                                      <tr>
                                        <td>IFSC Code</td>
                                        <td>{user?.payment?.ifsc}</td>
                                      </tr>
                                    </>
                                  )}
                                  {data?.mode === "easyPaisaName" && (
                                    <>
                                      <tr>
                                        <td>Easy Paisa Name</td>
                                        <td>{user?.payment?.easyPaisaName}</td>
                                      </tr>
                                      <tr>
                                        <td>Easy Paisa Account Number</td>
                                        <td>
                                          {user?.payment?.easyPaisaAccount}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Easy Paisa IFSC</td>
                                        <td>{user?.payment?.easyPaisaIfsc}</td>
                                      </tr>
                                    </>
                                  )}
                                  {data?.mode === "jazzCashaName" && (
                                    <>
                                      <tr>
                                        <td>Jazz Cash Name</td>
                                        <td>{user?.payment?.jazzCashaName}</td>
                                      </tr>
                                      <tr>
                                        <td>Jazz Cash Account Number</td>
                                        <td>
                                          {user?.payment?.jazzCashaAccount}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Jazz Cash IFSC</td>
                                        <td>{user?.payment?.jazzCashaIfsc}</td>
                                      </tr>
                                    </>
                                  )}
                                </thead>
                              </table>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </AdminLayout>
    </AdminHeader>
  );
}

export default AdminWithdraw;
