import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate, Link } from "react-router-dom";

function UserDetails({ allUsers }) {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [referralName, setReferralName] = useState("");
  const [totalReferralUsers, setTotalReferralUser] = useState(null);
  const getUser = async () => {
    axios({
      method: "post",
      withCredentials: true,
      url: `api/user/get-user-details`,
      data: {
        id: id,
      },
    })
      .then((res) => {
        setUser(res?.data?.data);
        getReferralUser(res.data.data.referralCode);
        getTotalReferralUser(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTotalReferralUser = (singleUser) => {
    const data = allUsers.filter((user) => {
      return singleUser.inviteCode === user.referralCode;
    });
    setTotalReferralUser(data);
  };

  const getReferralUser = async (code) => {
    try {
      const response = await axios({
        method: "post",
        withCredentials: true,
        url: `api/user/get-user-name-by-invite-code`,
        data: {
          inviteCode: code,
        },
      });

      if (response.data.success) {
        setReferralName(response.data.data);
      } else {
        setReferralName("Null");
      }
    } catch (error) {
      console.log(error);
      return "Error fetching user";
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, [id]);
  return (
    <div className="tab">
      <div className="welcome-container">
        <div className="username d-flex justify-content-between">
          <span>
            <b>User</b> Details
          </span>
          <span>
            <b>{user?.name}</b>
          </span>
        </div>
      </div>
      <div className="w-100 mt-4 " style={{ overflow: "auto" }}>
        <table className="w-100">
          <tbody>
            <tr key={user?._id} className="border">
              <th scope="row" className="text-start">
                Name
              </th>

              <td className="text-end fs-6 ">{user?.name}</td>
            </tr>
            <tr key={user?._id} className="border">
              <th scope="row" className="text-start">
                Number
              </th>

              <td className="text-end fs-6 ">{user?.number}</td>
            </tr>
            <tr key={user?._id} className="border">
              <th scope="row" className="text-start">
                Email
              </th>

              <td className="text-end fs-6 ">{user?.email}</td>
            </tr>
            <tr key={user?._id} className="border">
              <th scope="row" className="text-start">
                Referral Name
              </th>
              <td className="text-end fs-6 ">{referralName}</td>
            </tr>
            <tr key={user?._id} className="border">
              <th scope="row" className="text-start">
                Invite Code
              </th>
              <td className="text-end fs-6 ">
                {user?.inviteCode ? user.inviteCode : "Null"}
              </td>
            </tr>
            <tr key={user?._id} className="border">
              <th scope="row" className="text-start">
                Total Earning
              </th>
              <td className="text-end fs-6 ">{user?.earning}</td>
            </tr>
            <tr key={user?._id} className="border">
              <th scope="row" className="text-start">
                Total Withdraw Amount
              </th>
              <td className="text-end fs-6 ">{user?.withdrawAmount}</td>
            </tr>
            <tr key={user?._id} className="border">
              <th scope="row" className="text-start">
                City
              </th>
              <td className="text-end fs-6 ">
                {user?.city ? user.city : "Null"}
              </td>
            </tr>
            <tr key={user?._id} className="border">
              <th scope="row" className="text-start">
                Total Referrals
              </th>
              <td className="text-end fs-6 ">
                {totalReferralUsers?.length !== 0
                  ? totalReferralUsers?.length
                  : "Null"}
              </td>
            </tr>

            {/* Payment Details */}
            <tr key={user?._id} className="border">
              <th scope="row" className="text-start">
                Bank Details
              </th>
              <td className="text-end fs-6 ">
                <tr>
                  <td className="border-0 fw-bold">Bank Name:</td>
                  <td className="border-0">
                    {user?.payment?.name ? user?.payment?.name : "Null"}
                  </td>
                </tr>
                <tr>
                  <td className="border-0 fw-bold">IFSC Code:</td>
                  <td className="border-0">
                    {user?.payment?.ifsc ? user?.payment?.ifsc : "Null"}
                  </td>
                </tr>
                <tr>
                  <td className="border-0 fw-bold">Account:</td>
                  <td className="border-0">
                    {user?.payment?.account ? user?.payment?.account : "Null"}
                  </td>
                </tr>
              </td>
            </tr>
            <tr key={user?._id} className="border">
              <th scope="row" className="text-start">
                Easy Paisa
              </th>
              <td className="text-end fs-6 ">
                <tr>
                  <td className="border-0 fw-bold">Bank Name:</td>
                  <td className="border-0">
                    {user?.payment?.easyPaisaName
                      ? user?.payment?.easyPaisaName
                      : "Null"}
                  </td>
                </tr>
                <tr>
                  <td className="border-0 fw-bold">IFSC Code:</td>
                  <td className="border-0">
                    {user?.payment?.easyPaisaIfsc
                      ? user?.payment?.easyPaisaIfsc
                      : "Null"}
                  </td>
                </tr>
                <tr>
                  <td className="border-0 fw-bold">Account:</td>
                  <td className="border-0">
                    {user?.payment?.easyPaisaAccount
                      ? user?.payment?.easyPaisaAccount
                      : "Null"}
                  </td>
                </tr>
              </td>
            </tr>
            <tr key={user?._id} className="border">
              <th scope="row" className="text-start">
                Jazz Pay
              </th>
              <td className="text-end fs-6 ">
                <tr>
                  <td className="border-0 fw-bold">Bank Name:</td>
                  <td className="border-0">
                    {user?.payment?.jazzCashaName
                      ? user?.payment?.jazzCashaName
                      : "Null"}
                  </td>
                </tr>
                <tr>
                  <td className="border-0 fw-bold">IFSC Code:</td>
                  <td className="border-0">
                    {user?.payment?.jazzCashaIfsc
                      ? user?.payment?.jazzCashaIfsc
                      : "Null"}
                  </td>
                </tr>
                <tr>
                  <td className="border-0 fw-bold">Account:</td>
                  <td className="border-0">
                    {user?.payment?.jazzCashaAccount
                      ? user?.payment?.jazzCashaAccount
                      : "Null"}
                  </td>
                </tr>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="welcome-container mt-4">
        <div className="username d-flex justify-content-between">
          <span>
            All Referral Users of <b>{user?.name}</b>
          </span>
        </div>
      </div>
      <div className="w-100 mt-4 text-center" style={{ overflow: "auto" }}>
        {totalReferralUsers?.length !== 0 ? (
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Number</th>
                <th scope="col">Email</th>
                <th scope="col">Total Withdrawals</th>
                <th scope="col">Status</th>
                <th scope="col">View Details</th>
              </tr>
            </thead>
            <tbody>
              {totalReferralUsers?.map((user, index) => {
                return (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{user?.name}</td>
                    <td>{user?.number}</td>
                    <td>{user?.email}</td>
                    <td>{user?.withdrawAmount}</td>
                    <td
                      className={`${
                        user?.active ? "text-success" : "text-danger"
                      }`}
                    >
                      {user?.active ? "Active" : "Not Active"}
                    </td>
                    <td>
                      <a href={`/admin-users?id=${user?._id}`}>View Details</a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className="">No referrals yet</p>
        )}
      </div>
    </div>
  );
}

export default UserDetails;
