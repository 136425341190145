import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoneIcon from "@mui/icons-material/Done";
import HeroSection from "../components/Home/HeroSection";
import HowItWorks from "../components/Home/HowItWorks";
import WhyUs from "../components/Home/WhyUs";
import OtherSay from "../components/Home/OtherSay";
import BecomeAffiliate from "../components/Home/BecomeAffiliate";
import SiteInsights from "../components/Home/SiteInsights";
import OurReviews from "../components/Home/OurReviews";
import CourseSlider from "../components/Home/CourseSlider";
import Faq from "../components/Home/Faq";
import ChooseBetter from "../components/Home/ChooseBetter";
import ControlMoney from "../components/Home/ControlMoney";
import FingerTips from "../components/Home/FingerTips";
import HappyStudents from "../components/Home/HappyStudents";
import PopularTopics from "../components/Home/PopularTopics";
import Philosophy from "./Philosophy";
import ActNow from "./ActNow";
import HeroSectionTwo from "../components/Home/HeroSectionTwo";
import AllCourse from "../components/Course/AllCourse";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import "./Home.css";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [packages, setPackages] = useState(null);

  async function getAllPackages() {
    try {
      const res = await axios.get("/api/package/get-all-package");
      if (res.data.success) {
        setPackages(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllPackages();
  }, []);
  return (
    <Header>
      {/* <HeroSection /> */}
      <HeroSectionTwo />
      <ChooseBetter />
      <ControlMoney />
      <FingerTips />
      <div className="course-cont how-it-works">
        <h2 className="text-lg-center mb-4">
          Our
          <span> Courses</span>
        </h2>
        <div className="row">
          {packages?.map((item, index) => {
            return (
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="package">
                  <img src={item?.image} alt="" />
                  <h5>{item?.title}</h5>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>
                      <MenuBookIcon className="icon me-1" />
                      {item?.courseName?.split(",").length}
                      Courses
                    </span>
                   
                  </div>
                  <ul>
                    <li>
                      <DoneIcon className="icon me-2" />
                      Live Support
                    </li>
                    <li>
                      <DoneIcon className="icon me-2" />
                      10k+ Students Enrolled
                    </li>
                    <li>
                      <DoneIcon className="icon me-2" />
                      Certificate
                    </li>
                  </ul>
                  <button
                    onClick={() =>
                      navigate(`/course-detail/${item?.packageName}`)
                    }
                    className="button"
                  >
                    Get Started Now
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <HappyStudents />
      <HowItWorks />
      <ActNow />
      <Faq />
      {/* <Philosophy /> */}
      {/* <PopularTopics /> */}
      {/* <SiteInsights /> */}
      {/* <BecomeAffiliate /> */}
      {/* <OurReviews /> */}
      {/* <CourseSlider /> */}
      {/* <WhyUs /> */}
      {/* <OtherSay /> */}
    </Header>
  );
};

export default Home;
