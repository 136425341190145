import React, { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import menu from "./layout/UserMenu";
import "./UserSideMenu.css";
import { useSelector } from "react-redux";

const UserSideMenu = ({
  userMenu,
  setUserMenu,
  CourseMenu,
  sequence,
  setSequence,
}) => {
  const location = useLocation();
  const params = useParams();
  const { user } = useSelector((state) => state.user);

  const coursePageMenu =
    location.pathname === `/course/${params.courseName}` && CourseMenu;
  const showMenu =
    location.pathname === `/course/${params.courseName}` ? CourseMenu : menu;
  return (
    <div className={`user-side-menu ${userMenu ? "active" : ""}`}>
      <div className="d-flex justify-content-end">
        <CloseIcon
          className="closeIcon"
          onClick={() => setUserMenu(!userMenu)}
        />
      </div>
      <hr />
      <div className="user-menus">
        <ul>
          {coursePageMenu
            ? coursePageMenu?.map((item, index) => {
                const IconComponent = item.icon;
                return (
                  <li
                    className={`${
                      location.pathname === `${item.path}` ? "active" : ""
                    }`}
                  >
                    <IconComponent />
                    <Link
                      className="ms-2"
                      to="#"
                      onClick={() => {
                        setSequence(item.seq);
                        setUserMenu(!userMenu);
                      }}
                    >
                      {item.name}
                    </Link>
                  </li>
                );
              })
            : showMenu?.map((item, index) => {
                const IconComponent = item.icon;
                return (
                  <li
                    className={`${
                      location.pathname === `${item.path}` ? "active" : ""
                    }`}
                  >
                    <IconComponent />
                    <Link className="ms-2 position-relative" to={item.path}>
                      {((item.name === "My Profile" &&
                        (!user?.state || !user?.city || !user?.photo)) ||
                        (item.name === "Payment Method" && !user?.payment)) && (
                        <>
                          {
                            <span
                              className=" rounded-circle bg-danger p-1"
                              style={{
                                position: "absolute",
                                right: "-20px",
                                top: "6px",
                              }}
                            >
                              <span className="visually-hidden">
                                unread messages
                              </span>
                            </span>
                          }
                        </>
                      )}
                      {item.name}
                    </Link>
                  </li>
                );
              })}
        </ul>
      </div>
    </div>
  );
};

export default UserSideMenu;
