import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../SideMenu/SideMenu.css";
import HomeIcon from "@mui/icons-material/Home";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SchoolIcon from "@mui/icons-material/School";
import Person2Icon from "@mui/icons-material/Person2";
import InfoIcon from "@mui/icons-material/Info";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PersonAddIcon from "@mui/icons-material/Person";
import LoginIcon from "@mui/icons-material/Login";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { SetLogin } from "../../redux/features/loginSlice";
import IMAGES from "../../img";

const SideMenu = ({ user, login, sideMenu, setSideMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // Logout Handler
  const handleLogout = () => {
    setLoading(true);
    axios({
      method: "POST",
      withCredentials: true,
      url: "api/user/logout",
    })
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          dispatch(SetLogin(false));
          navigate("/login");
        }, 1500);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <div
      className={`sidemenu ${sideMenu ? "active" : ""}`}
      onClick={() => setSideMenu(!sideMenu)}
    >
      <div className="sidemenu-menus">
        <Link
          className={`${login ? "d-none" : "d-block"}`}
          to="/"
          onClick={() => setSideMenu(!sideMenu)}
        >
          <HomeIcon className="me-2" />
          <span>Home</span>
        </Link>
        <Link
          className={`${login ? "d-none" : "d-block"}`}
          to="/about"
          onClick={() => setSideMenu(!sideMenu)}
        >
          <InfoIcon className="me-2" />
          <span>About</span>
        </Link>
   
       
        <Link
          className={`${login && user?.admin ? "" : "d-none"}`}
          to="/admin-dashboard"
          onClick={() => setSideMenu(!sideMenu)}
        >
          <DashboardIcon className="me-2" />
          <span>Admin</span>
        </Link>
        {user?.active ? (
          <Link
            className={`${login ? "" : "d-none"}`}
            to="/dashboard"
            onClick={() => setSideMenu(!sideMenu)}
          >
            <DashboardIcon className="me-2" />
            <span>Dashboard</span>
          </Link>
        ) : (
          <Link
            className={`${login ? "" : "d-none"}`}
            to="/activate"
            onClick={() => setSideMenu(!sideMenu)}
          >
            <DashboardIcon className="me-2" />
            <span>Dashboard</span>
          </Link>
        )}
        <Link
          className={`${login && user?.active ? "" : "d-none"}`}
          to="/mycourse"
          onClick={() => setSideMenu(!sideMenu)}
        >
          <SchoolIcon className="me-2" />
          <span>Courses</span>
        </Link>

        <Link
          className={`${login && user?.active ? "" : "d-none"}`}
          to="/wallet"
          onClick={() => setSideMenu(!sideMenu)}
        >
          <SchoolIcon className="me-2" />
          <span>Wallet</span>
        </Link>
        <Link
          className={`${login && user?.active ? "" : "d-none"}`}
          to="/profile"
          onClick={() => setSideMenu(!sideMenu)}
        >
          <Person2Icon className="me-2" />
          <span>My Profile</span>
        </Link>
        <Link to="/contact" onClick={() => setSideMenu(!sideMenu)}>
          <SupportAgentIcon className="me-2" />
          <span>Contact</span>
        </Link>
        {/* Coin  */}
        <button
          className={`coin-btn ${login && user?.active ? "d-block" : "d-none"}`}
          onClick={() => {
            navigate("/wallet");
          }}
        >
          <span>
            <b>Amount: {user?.amount}</b>
          </span>
        </button>
        {/* Register  */}
        <Link
          className={`${
            login ? "d-none" : "d-block"
          } mobile-register-btn text-white`}
          to="/register"
        >
          <PersonAddIcon className="me-2" />
          <span>Register</span>
        </Link>
        {/* Login  */}
        <Link
          className={`${login ? "d-none" : "d-block"} login-btn`}
          to="/login"
          style={{
            backgroundColor: "#000",
            color: "white",
            border: "1px solid black",
          }}
          onClick={() => setSideMenu(!sideMenu)}
        >
          <LoginIcon className="me-2" />
          <span>Login</span>
        </Link>
        {/* Logout  */}
        <button
          className={`mobile-login-btn ${login ? "d-block" : "d-none"}`}
          onClick={handleLogout}
        >
          {loading ? (
            <div className="d-flex align-items-center justify-content-center">
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="ms-2">Loading...</span>
            </div>
          ) : (
            "Logout"
          )}
          <LoginIcon className="LoginIcon ms-2" />
        </button>
      </div>
    </div>
  );
};

export default SideMenu;
