import DashboardIcon from "@mui/icons-material/Dashboard";
import SchoolIcon from "@mui/icons-material/School";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import FaceIcon from "@mui/icons-material/Face";
import PaymentIcon from "@mui/icons-material/Payment";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";

const AdminMenu = [
  {
    name: "Dashboard",
    path: "/admin-dashboard",
    icon: DashboardIcon,
  },
  {
    name: "Courses",
    path: "/admin-course",
    icon: SchoolIcon,
  },
  {
    name: "Packages",
    path: "/admin-package",
    icon: SchoolIcon,
  },
  {
    name: "Users",
    path: "/admin-users",
    icon: FaceIcon,
  },
  // {
  //   name: "Payments",
  //   path: "/admin-payments",
  //   icon: PaymentIcon,
  // },
  {
    name: "Withdraw Requests",
    path: "/admin-withdraw",
    icon: PointOfSaleIcon,
  },
  // {
  //   name: "Add Banner",
  //   path: "/admin-add-banner",
  //   icon: ViewCarouselIcon,
  // },
  {
    name: "Queries",
    path: "/admin-queries",
    icon: ContactSupportIcon,
  },
];

export default AdminMenu;
