import React from "react";
import Header from "../components/Header/Header";
import WeAre from "../components/About/WeAre";
import Text from "../components/About/Text";
import "./About.css";

const About = () => {
  return (
    <Header>
      <div className="container-fluid about-container pt-0">
        <div className="about-img"></div>
        <WeAre />
        <Text />
      </div>
    </Header>
  );
};

export default About;
