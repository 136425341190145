import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SetAdmin } from "../../redux/features/adminSlice";
import { useDispatch } from "react-redux";

const AdminProtectedRoute = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    axios({
      method: "get",
      withCredentials: true,
      url: `api/user/get-user`,
    })
      .then((res) => {
        if (res.data.success) {
          if (res.data.data?.admin === true) {
            setIsLogin(true);
            dispatch(SetAdmin(true));
            if (props.route) {
              return navigate(props.route);
            } else {
              return navigate("/admin-dashboard");
            }
          } else {
            dispatch(SetAdmin(false));
            setIsLogin(false);
            return navigate("/login");
          }
        }
      })
      .catch((err) => {
        navigate("/login");
        setIsLogin(false);
        dispatch(SetAdmin(false));
        console.log(err);
      });

    // eslint-disable-next-line
  }, []);

  return <>{isLogin && <div>{props.children}</div>}</>;
};

export default AdminProtectedRoute;
