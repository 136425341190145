import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/userSlice";
import loginReducer from "./features/loginSlice";
import videoReducer from "./features/videoSlice";
import adminReducer from "./features/adminSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    admin: adminReducer,
    login: loginReducer,
    allCourseVideos: videoReducer,
  },
});
