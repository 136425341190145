import React from "react";
import Slider from "react-slick";
import IMAGES from "../../img";
import "./HappyStudents.css";

const HappyStudents = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const reviews = [
    {
      id: "1",
      name: "Sara Ansari",
      userImg: `${IMAGES.r1}`,
      coins: "1500",
      msg: "Thanks for this website. I am earning and learning at the same time",
      city: "Karachi",
    },
    {
      id: "2",
      name: "Rahul Sharma",
      userImg: `${IMAGES.r2}`,
      coins: "3000",
      msg: "SkillRiseUp website se mujhe bhot fayda hua hai",
      city: "Lahore",
    },
    {
      id: "3",
      name: "Aliya Shaikh",
      userImg: `${IMAGES.r3}`,
      coins: "2700",
      msg: "Mujhe achi income ho jati hai website hai thanks SkillRiseUp",
      city: "Karachi",
    },
    {
      id: "4",
      name: "Faiz Khan",
      userImg: `${IMAGES.r4}`,
      coins: "6000",
      msg: "I will recommend this website to everyone! Thank you so much SkillRiseUp",
      city: "Punjab",
    },
    {
      id: "5",
      name: "Himanshu verma",
      userImg: `${IMAGES.r5}`,
      coins: "7200",
      msg: "Paisa kamane k liye best website hai yeh thank u SkillRiseUp",
      city: "Lahore",
    },
    {
      id: "6",
      name: "Dilip Yadhav",
      userImg: `${IMAGES.r6}`,
      coins: "9600",
      msg: "Thank you yeh website k liye maine achi income ki hai isse",
      city: "Lahore",
    },
    {
      id: "7",
      name: "Nav Khan",
      userImg: `${IMAGES.r8}`,
      coins: "12000",
      msg: "Today I made nearly about 6000rs and withdrawn succesfully",
      city: "Lahore",
    },
    {
      id: "8",
      name: "Faizan Shaikh",
      userImg: `${IMAGES.r4}`,
      coins: "15000",
      msg: "Guys maine yeh website se 5500 ki income easily generate ki hai aaj",
      city: "Karachi",
    },
    {
      id: "9",
      name: "Sunil Sharma",
      userImg: `${IMAGES.r9}`,
      coins: "25200",
      msg: "Best website to learn and grow your skills and also earn",
      city: "Lahore",
    },
    {
      id: "10",
      name: "Sohail Shaikh",
      userImg: `${IMAGES.r10}`,
      coins: "4500",
      msg: "Earning k liye achi website hai and also maine wordpress course sikha hai. very nice tutorial!",
      city: "Punjab",
    },
    {
      id: "11",
      name: "Vijay Sharma",
      userImg: `${IMAGES.r11}`,
      coins: "6700",
      msg: "I recommend everyone to join this website! It is the best platform for growing your skills",
      city: "Karachi",
    },
    {
      id: "12",
      name: "Akash Shinde",
      userImg: `${IMAGES.r12}`,
      coins: "9300",
      msg: "A big thanks to the SkillRiseUp. Earning a lot while doing a job :)",
      city: "Karachi",
    },
    {
      id: "13",
      name: "Soniya Sharma",
      userImg: `${IMAGES.r13}`,
      coins: "5200",
      msg: "Courses are good and well explained. Have invited many of my friends and earning good! Thanks",
      city: "Lahore",
    },
    {
      id: "14",
      name: "Owes Shaikh",
      userImg: `${IMAGES.r14}`,
      coins: "3200",
      msg: "I am writing this review so that It helps others users. Must recommened website to join to earn and learn",
      city: "Punjab",
    },
  ];
  return (
    <React.Fragment>
      <div className="container-fluid happy-students-container">
        <h2 className="mb-5">
          <span>Happy Students</span> <br />
          are our true wealth
        </h2>
        <div className="happy-students-slider">
          <Slider {...settings}>
            {reviews.map((item, index) => {
              if (index > 10) {
                return false;
              }
              return (
                <div key={index} className="student-card">
                  <div className="student-msg">
                    "<span>{item.msg}</span>"
                  </div>
                  <div className="student-detail">
                    <div className="student-img-name">
                      <img width="50px" src={item.userImg} alt="" />
                      <span className="name">
                        <b>{item.name}</b> <br />
                        {item.city}
                      </span>
                    </div>
                    
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HappyStudents;
