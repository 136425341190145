import React from "react";
import Header from "../components/Header/Header";
import "./Register.css";

const ConfirmOtp = () => {
  return (
    <div>
      <Header>
        <div className="container-fluid bg-light register-form-container">
          <div className="form">
            <div className="row">
              <div className="register-img col-12 col-sm-12 col-md-4 col-lg-4">
                {/* <img width="100%" src={IMAGES.register} alt="load" /> */}
              </div>
              <div className="col-12 col-sm-12 col-md-8 col-lg-8 px-4">
                <form>
                  <h5 className="mb-4 position-relative line">Confirm Otp</h5>
                  {/* email  */}
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Enter otp
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Enter your email here"
                      aria-describedby="emailHelp"
                      required
                    />
                  </div>
                  <button type="submit" className="reg-btn">
                    Next
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Header>
    </div>
  );
};

export default ConfirmOtp;
