import React from "react";
import "./ControlMoney.css";

const ControlMoney = () => {
  return (
    <div className="control-money-container">
      <h2>
        <span>Have control</span> on
        <br />
        your money
      </h2>
    </div>
  );
};

export default ControlMoney;
