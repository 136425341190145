import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminHeader from "../Header/AdminHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Multiselect from "multiselect-react-dropdown";
import { message } from "antd";
import AdminLayout from "../layout/AdminLayout";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "./AdminPackage.css";

const AdminPackage = () => {
  const [courses, setCourses] = useState(null);
  const [packages, setPackages] = useState(null);
  const [data, setData] = useState([]);
  const [file, setFile] = useState(null);
  const [editPack, setEditPack] = useState(false);
  const [pack, setPack] = useState(null);
  const [packFile, setPackFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  }

  function handleMultiselectChange(selectedList) {
    setData((prevData) => ({
      ...prevData,
      courseName: selectedList.map((item) => item.trim()),
    }));
  }
  function handleMultiselectPackChange(selectedList) {
    setPack((prevData) => ({
      ...prevData,
      courseName: selectedList.map((item) => item.trim()),
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("image", file);
      for (let key in data) {
        formData.append(key, data[key]);
      }
      const res = await axios.post("/api/package/add-package", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setData({
          packageName: "",
          title: "",
          price: "",
          duration: "",
          courses: [],
        });
        setFile(null);
        getAllPackages();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAllCourse() {
    try {
      const res = await axios.get("/api/course/get-all-course");
      if (res.data.success) {
        setCourses(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAllPackages() {
    try {
      const res = await axios.get("/api/package/get-all-package");
      if (res.data.success) {
        setPackages(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handlePackChange(e) {
    setPack({ ...pack, [e.target.name]: e.target.value });
  }

  async function handleUpdatePack(e) {
    try {
      const formData = new FormData();
      formData.append("image", packFile);
      for (let key in pack) {
        formData.append(key, pack[key]);
      }
      const res = await axios.post("/api/package/edit-package", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setPack(null);
        setPackFile(null);
        setEditPack(false);
        getAllPackages();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllCourse();
    getAllPackages();
  }, []);

  async function handleDeletePackage(packageName) {
    const confirm = window.confirm("Are you sure to delete?");
    if (confirm) {
      try {
        const res = await axios.post("/api/package/delete-package", {
          packageName: packageName,
        });
        if (res.data.success) {
          message.success(res.data.message);
          getAllPackages();
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <AdminHeader>
      <AdminLayout>
        <div className="welcome-container">
          <div className="username d-flex justify-content-between">
            <span>Packages</span>
          </div>
        </div>
        <div className="row">
          <div className="mt-5 col-12 col-sm-12 col-md-4 col-lg-4">
            <div className="package-container">
              <h6>Add Packages</h6>
              <input
                onChange={handleChange}
                value={data?.packageName}
                type="text"
                name="packageName"
                className="form-control mb-3"
                placeholder="Enter Package Name"
              />
              <input
                onChange={handleChange}
                value={data?.price}
                type="text"
                name="price"
                className="form-control mb-3"
                placeholder="Enter Price"
              />
              <input
                onChange={handleChange}
                value={data?.duration}
                type="text"
                name="duration"
                className="form-control mb-3"
                placeholder="Enter Duration"
              />
              <input
                onChange={handleFileChange}
                type="file"
                name="image"
                className="form-control mb-3"
              />
              <Multiselect
                id="category"
                className="multiselect-wrapper"
                isObject={false}
                options={courses?.map((item) => item.courseName)}
                selectedValues={data?.courses?.map((item) => item)}
                onSelect={handleMultiselectChange}
                onRemove={handleMultiselectChange}
              />
              <button onClick={handleSubmit} className="button mt-3">
                Submit
              </button>
            </div>
          </div>
          {editPack ? (
            <div className="mt-5 col-12 col-sm-12 col-md-8 col-lg-8">
              <button
                className="btn btn-dark mb-3"
                onClick={() => setEditPack(false)}
              >
                Back
              </button>
              <input
                onChange={handlePackChange}
                value={pack?.packageName}
                type="text"
                name="packageName"
                className="form-control mb-3"
                placeholder="Enter Package Name"
              />
              <input
                onChange={handlePackChange}
                value={pack?.price}
                type="text"
                name="price"
                className="form-control mb-3"
                placeholder="Enter Price"
              />
              <input
                onChange={handlePackChange}
                value={pack?.duration}
                type="text"
                name="duration"
                className="form-control mb-3"
                placeholder="Enter Duration"
              />
              <input
                onChange={(e) => setPackFile(e.target.files[0])}
                // value={packFile}
                type="file"
                name="image"
                className="form-control mb-3"
              />
              <Multiselect
                id="category"
                className="multiselect-wrapper"
                isObject={false}
                options={courses?.map((item) => item.courseName)}
                selectedValues={pack?.courses?.map((item) => item)}
                onSelect={handleMultiselectPackChange}
                onRemove={handleMultiselectPackChange}
              />
              <button
                className="btn btn-success mt-3"
                onClick={handleUpdatePack}
              >
                Update Package
              </button>
            </div>
          ) : (
            <div className="mt-5 col-12 col-sm-12 col-md-8 col-lg-8">
              {packages?.map((item, index) => {
                return (
                  <div className="packages" key={index}>
                    <EditIcon
                      onClick={() => {
                        setEditPack(true);
                        setPack(item);
                      }}
                      className="edit-icon icon text-danger"
                    />
                    <DeleteIcon
                      onClick={() => handleDeletePackage(item?.packageName)}
                      className="icon text-danger"
                    />
                    <div className="pack-img">
                      <img src={item.image} alt="" />
                    </div>
                    <div className="pack-content">
                      <h5 className="mb-3 m-0 text-dark">
                        <b>{item?.packageName}</b>
                      </h5>
                      <p>Rs. {item?.price}</p>
                      <p>Duration - {item?.duration}</p>
                      <p className="m-0">
                        <b>Courses List</b>
                      </p>
                      {item?.courseName?.split(",").map((item) => {
                        return <p className="m-0">{item}</p>;
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </AdminLayout>
    </AdminHeader>
  );
};

export default AdminPackage;
