import React, { useEffect, useState } from "react";
import AdminLayout from "../layout/AdminLayout";
import AdminHeader from "../Header/AdminHeader";
import GroupIcon from "@mui/icons-material/Group";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import "./AdminDashboard.css";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState(null);
  const [queries, setQueries] = useState(null);
  const [userCount, setUserCount] = useState(null);
  const [courseCount, setCourseCount] = useState(null);
  const [packageCount, setPackageCount] = useState(null);
  const [totalWithdraw, setTotalWithdraw] = useState(null);
  const [pendingPayout, setPendingPayout] = useState(null);
  const [error, setError] = useState(null);

  function formatNumber(number) {
    const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
    const tier = (Math.log10(Math.abs(number)) / 3) | 0;
    if (tier === 0) return number;
    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);
    const scaled = number / scale;
    return scaled.toFixed(1) + suffix;
  }

  const getDashboardCount = async () => {
    try {
      const res = await axios.get("/api/admin/get-dashboard-count", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setAllUsers(res.data.data.allusers.reverse());
        setQueries(res.data.data.contacts.reverse());
        setUserCount(res.data.data.totalUsers);
        setCourseCount(res.data.data.totalCourses);
        setPackageCount(res.data.data.totalPackage);
        setTotalWithdraw(res.data.data.totalWithdraw);
        setPendingPayout(res.data.data.pendingPayout);
      } else {
        message.error("Error");
      }
    } catch (error) {
      console.error("Error fetching user count:", error);
      setError("Failed to fetch user count. Please try again later.");
    }
  };

  useEffect(() => {
    getDashboardCount();
  }, []);

  return (
    <AdminHeader>
      <AdminLayout className="a-dashboard">
        <div className="admin-dashboard-container">
          <div
            className="admin-dashboard-card"
            onClick={() => navigate("/admin-users")}
          >
            <div className="details">
              <h1>
                <b>{userCount || 0}</b>
              </h1>
              <span>All Users</span>
            </div>
            <div className="icon-container">
              <GroupIcon className="icon" />
            </div>
          </div>
          <div
            className="admin-dashboard-card"
            onClick={() => navigate("/admin-course")}
          >
            <div className="details">
              <h1>
                <b>{courseCount || 0}</b>
              </h1>
              <span>Course</span>
            </div>
            <div className="icon-container">
              <AutoStoriesIcon className="icon" />
            </div>
          </div>
          <div
            className="admin-dashboard-card"
            onClick={() => navigate("/admin-package")}
          >
            <div className="details">
              <h1>
                <b>{packageCount || 0}</b>
              </h1>
              <span>Packages</span>
            </div>
            <div className="icon-container">
              <CollectionsBookmarkIcon className="icon" />
            </div>
          </div>
          <div
            className="admin-dashboard-card"
            onClick={() => navigate("/admin-withdraw")}
          >
            <div className="details">
              <h1>
                <b>{formatNumber(pendingPayout) || 0}</b>
              </h1>
              <span>Pending Payout</span>
            </div>
            <div className="icon-container">
              <MoneyOffIcon className="icon" />
            </div>
          </div>
          <div
            className="admin-dashboard-card"
            onClick={() => navigate("/admin-withdraw")}
          >
            <div className="details">
              <h1>
                <b>{formatNumber(totalWithdraw) || 0}</b>
              </h1>
              <span>Payout Completed</span>
            </div>
            <div className="icon-container">
              <AccountBalanceIcon className="icon" />
            </div>
          </div>
          <div
            className="admin-dashboard-card"
            onClick={() => navigate("/admin-withdraw")}
          >
            <div className="details">
              <h1>
                <b>{formatNumber(totalWithdraw / 2) || 0}</b>
              </h1>
              <span>Total Profit</span>
            </div>
            <div className="icon-container">
              <MonetizationOnIcon className="icon" />
            </div>
          </div>
        </div>
        <div className="admin-recent-things">
          <div className="admin-recent-users">
            <h6>Recent Users</h6>
            <hr />
            <table className="table tb table-bordered mt-3">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Package</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {allUsers &&
                  allUsers
                    .map((item, index) => {
                      return (
                        <tr>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{item.number}</td>
                          <td>{item.course[0]}</td>
                          <td>{item.active ? "Active" : "Not Active"}</td>
                        </tr>
                      );
                    })
                    .splice(0, 10)}
              </tbody>
            </table>
          </div>
          <div className="admin-recent-queries">
            <h6>Recent Queries</h6>
            <hr />
            <table className="table tb table-bordered mt-3">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Message</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {queries &&
                  queries
                    .map((item, index) => {
                      return (
                        <tr>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>
                            <button className="btn btn-dark">View</button>
                          </td>
                          <td>
                            <small>
                              <small>
                                {new Date(item.createdAt).toLocaleString(
                                  "default",
                                  {
                                    day: "numeric",
                                    month: "long",
                                    year: "numeric",
                                  }
                                )}
                              </small>
                            </small>
                          </td>
                        </tr>
                      );
                    })
                    .splice(0, 10)}
              </tbody>
            </table>
          </div>
        </div>
      </AdminLayout>
    </AdminHeader>
  );
};

export default AdminDashboard;
