import React from "react";
import Header from "../components/Header/Header";

const Disclaimer = () => {
  return (
    <Header>
      <div className="container p-5">
        <h4>
          <b>Disclaimer for The SkillRiseUp Course Website</b>
        </h4>
        <p>
          Welcome to The SkillRiseUp! This disclaimer outlines the limitations of
          liability and information provided on our website. By accessing or
          using the The SkillRiseUp Course Website ("www.SkillRiseUp.com"),
          you agree to be bound by this disclaimer.
        </p>
        <b>1.Information Accuracy</b>
        <br />
        - The content on this Website is provided for general informational
        purposes only. While we strive to ensure the accuracy and currency of
        the information, we make no representations or warranties of any kind,
        express or implied, about the completeness, accuracy, reliability,
        suitability, or availability with respect to the Website or the
        information, products, services, or related graphics contained on the
        Website for any purpose. Any reliance you place on such information is
        strictly at your own risk.
        <br />
        <br />
        <b>2.No Professional Advice</b>
        <br /> - The information provided on this Website does not constitute
        professional advice. Any decisions you make based on the information
        found on this Website are strictly at your own risk. We recommend
        consulting with a qualified professional for specific advice tailored to
        your situation.
        <br />
        <br />
        <b>3.Course Content</b>
        <br /> - Our courses are provided for educational purposes and do not
        guarantee specific outcomes. Results may vary based on individual
        effort, comprehension, and application of the material.
        <br />
        <br />
        <b>4.Third-Party Links</b>
        <br /> - This Website may contain links to third-party websites. We have
        no control over the content, nature, or availability of those sites. The
        inclusion of any links does not necessarily imply a recommendation or
        endorse the views expressed within them.
        <br />
        <br />
        <b>5.Limitation of Liability</b>
        <br /> - In no event will The SkillRiseUp be liable for any loss or
        damage including without limitation, indirect or consequential loss or
        damage, or any loss or damage whatsoever arising from loss of data or
        profits arising out of, or in connection with, the use of this Website.
        <br />
        <br />
        <b>6.Changes to Content</b>
        <br /> - We reserve the right to modify or discontinue, temporarily or
        permanently, any part or all of the Website and its content with or
        without notice.
        <br />
        <br />
        <b>7.Governing Law</b>
        <br />- This disclaimer shall be governed by and construed in accordance
        with the laws of [Jurisdiction], and any disputes relating to this
        disclaimer will be subject to the exclusive jurisdiction of the courts
        of [Jurisdiction].
        <br />
        <br />
        <b>8.Contact Us</b>
        <br /> - If you have any questions or concerns about this Disclaimer,
        please contact us at [info@The SkillRiseUp.com].
        <br />
        <br />
        By using the The SkillRiseUp Course Website, you acknowledge that you
        have read, understood, and agree to the terms outlined in this
        Disclaimer.
      </div>
    </Header>
  );
};

export default Disclaimer;
