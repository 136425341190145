import React, { useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./AccountRecords.css";
import Header from "../components/Header/Header";
import Layout from "./layout/Layout";
import ReferredUsers from "./components/ReferredUsers";
import RechargeHistory from "./components/RechargeHistory";
import WithdrawHistory from "./components/WithdrawHistory";
import CourseHistory from "./components/CourseHistory";
import { useSelector } from "react-redux";
import axios from "axios";
import IMAGES from "../img";
import { useParams } from "react-router-dom";
import CoursePurchaseDetails from "./components/CoursePurchaseDetails";

const AccountRecords = () => {
  const { user } = useSelector((state) => state.user);
  const [referredUser, setReferredUser] = useState(null);
  const [packages, setPackages] = useState(null);
  const [addCoinAllRecords, setAddCoinAllRecords] = useState(null);
  const [allWithDrawals, setAllWithdrawals] = useState(null);
  const [totalRecharge, setTotalRecharge] = useState(null);
  const [loading, setLoading] = useState(false);

  console.log(packages);

  const [tab, setTab] = useState(0);
  const params = useParams();

  const getAllReferredUser = () => {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        inviteCode: user?.inviteCode,
      },
      withCredentials: true,
      url: `api/user/get-all-referred-user`,
    })
      .then((res) => {
        if (res.data.success) {
          setTimeout(() => {
            setLoading(false);
            setReferredUser(res.data.data.reverse());
            setPackages(res.data.package);
          }, 1500);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllAddCoinRecords = () => {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        userId: user?._id,
      },
      withCredentials: true,
      url: `api/payment/get-all-add-coin-records`,
    })
      .then((res) => {
        if (res.data.success) {
          setTimeout(() => {
            setLoading(false);
            const data = res.data.data;
            setAddCoinAllRecords(data.reverse());
            let amount = 0;
            data.map((data) => {
              return (amount += data.amount);
            });
            setTotalRecharge(amount);
          }, 1500);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllWithDrawals = () => {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        id: user?._id,
      },
      withCredentials: true,
      url: `api/withdraw/get-all-withdrawals`,
    })
      .then((res) => {
        if (res.data.success) {
          setTimeout(() => {
            setLoading(false);
            setAllWithdrawals(res.data.data.reverse());
          }, 1500);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (tab === 1) {
      getAllReferredUser();
    } else if (tab === 2) {
      getAllAddCoinRecords();
    } else if (tab === 3) {
      getAllWithDrawals();
    }
    // eslint-disable-next-line
  }, [tab]);

  useEffect(() => {
    if (user) {
      getAllReferredUser();
      getAllAddCoinRecords();
      getAllWithDrawals();
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (params?.tab === "referral") {
      setTab(1);
    } else if (params?.tab?.includes("@")) {
      setTab(5);
    } else {
      setTab(0);
    }
    // eslint-disable-next-line
  }, [params]);
  return (
    <Header>
      <Layout>
        {/* welcome container  */}
        <div className="welcome-container">
          <h5>Account Records</h5>
          <hr />
        </div>
        {/* all Tab container  */}
        <div
          className={`account-records d-flex ${
            tab === 0 ? "d-block" : "d-none"
          }`}
        >
          <div className="record-card" onClick={() => setTab(1)}>
            <div className="icon-cont">
              <PersonIcon className="icon" />
            </div>
            <div className="d-content text-start">
              <p className="m-0">Referrals</p>
              <h3>
                <b>{referredUser?.length}</b>
              </h3>
            </div>
          </div>
          {/* <div className="record-card" onClick={() => setTab(2)}>
            <div className="icon-cont">
              <PersonIcon className="icon" />
            </div>
            <div className="d-content text-start">
              <p className="m-0">Recharge</p>
              <h3>
                <b>{addCoinAllRecords?.length}</b>
              </h3>
            </div>
          </div> */}
          <div className="record-card" onClick={() => setTab(3)}>
            <div className="icon-cont">
              <PersonIcon className="icon" />
            </div>
            <div className="d-content text-start">
              <p className="m-0">Withdrawals</p>
              <h3>
                <b>{allWithDrawals?.length}</b>
              </h3>
            </div>
          </div>
          <div className="record-card" onClick={() => setTab(4)}>
            <div className="icon-cont">
              <PersonIcon className="icon" />
            </div>
            <div className="d-content text-start">
              <p className="m-0">Courses</p>
              <h3>
                <b>{user?.course?.length}</b>
              </h3>
            </div>
          </div>
        </div>
        {/* Referral Tab  */}
        <div className={`refferal-tab ${tab === 1 ? "d-block" : "d-none"}`}>
          <div className="back-btn" onClick={() => setTab(0)}>
            <ArrowBackIosIcon className="icon" />
            Go Back
          </div>
          <ReferredUsers
            packages={packages}
            loading={loading}
            referredUser={referredUser}
          />
        </div>
        {/* Recharge Tab  */}
        <div className={`recharge-tab ${tab === 2 ? "d-block" : "d-none"}`}>
          <div className="back-btn" onClick={() => setTab(0)}>
            <ArrowBackIosIcon className="icon" />
            Go Back
          </div>
          <RechargeHistory
            loading={loading}
            addCoinAllRecords={addCoinAllRecords}
            totalHeading={"Total Recharge"}
            totalValue={totalRecharge}
          />
        </div>
        {/* Withdrawal Tab  */}
        <div className={`withdrawal-tab ${tab === 3 ? "d-block" : "d-none"}`}>
          <div className="back-btn" onClick={() => setTab(0)}>
            <ArrowBackIosIcon className="icon" />
            Go Back
          </div>
          <WithdrawHistory
            img={IMAGES?.coin}
            value="3"
            heading={"My Withdrawal History"}
            id={user?._id}
          />
        </div>
        {/* Course Tab */}
        <div className={`course-tab ${tab === 4 ? "d-block" : "d-none"}`}>
          <div className="back-btn" onClick={() => setTab(0)}>
            <ArrowBackIosIcon className="icon" />
            Go Back
          </div>
          <CourseHistory heading="My Course" />
        </div>
        <div className={`course-tab ${tab === 5 ? "d-block" : "d-none"}`}>
          <div className="back-btn" onClick={() => setTab(1)}>
            <ArrowBackIosIcon className="icon" />
            Go Back
          </div>
          <CoursePurchaseDetails />
        </div>
      </Layout>
    </Header>
  );
};

export default AccountRecords;
