import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AdminLayout from "../layout/AdminLayout";
import AdminHeader from "../Header/AdminHeader";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

function Admin() {
  const navigate = useNavigate();
  const [allCourse, setAllCourse] = useState(null);
  const [form, setForm] = useState({ courseName: "", price: 0, image: null });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("courseName", form?.courseName);
    formData.append("price", form?.price);
    formData.append("image", form?.image);
    axios({
      method: "POST",
      data: formData,
      withCredentials: true,
      url: `api/course/add-course`,
    })
      .then((res) => {
        getAllCourse();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCourse = (e, val)=>{
    e.stopPropagation()
    axios({
      method: "post",
      withCredentials: true,
      url: `api/course/delete-course`,
      data: {
        id: val
      }
    })
      .then((res) => {
        if(res.data.success){
          getAllCourse();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getAllCourse = async () => {
    axios({
      method: "get",
      withCredentials: true,
      url: `api/course/get-all-course`,
    })
      .then((res) => {
        setAllCourse(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllCourse();
    // eslint-disable-next-line
  }, []);
  return (
    <AdminHeader>
      <AdminLayout>
        <div className="tab">
          <div className="p-3 mb-3 border" style={{backgroundColor: '#d6eeff', borderRadius: '10px'}}>
            <form className="row" onSubmit={handleSubmit} >
              <div className="col-12 col-md-6 p-2">
                <input
                  className="form-control"
                  type="text"
                  name="courseName"
                  value={form?.courseName}
                  placeholder="Course Name"
                  onChange={(e) => setForm({ ...form, courseName: e.target.value })}
                />
              </div>
              <div className="col-12 col-md-6 p-2">
                <input
                  className="form-control"
                  type="text"
                  name="price"
                  placeholder="Course Price"
                  value={form?.price}
                  onChange={(e) => setForm({ ...form, price: e.target.value })}
                />
              </div>
              <div className="col-12 col-md-6 p-2">
                <input
                  className="form-control"
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  placeholder="select image"
                  onChange={(e) => setForm({ ...form, image: e.target.files[0] })}
                />
              </div>
              <div className="col-12 col-md-3 p-2">
                <button className="btn btn-primary" type="submit">Add Course</button>
              </div>
            </form>
          </div>
          <div className="welcome-container">
            <div className="username d-flex justify-content-between">
              <span>
                <b>All</b> Course
              </span>
              <span>
                <b>{allCourse?.length}</b>
              </span>
            </div>
          </div>
          <div className="mt-4 user-course-container">
            {allCourse?.map((course) => {
              return (
                <>
                  <div
                    key={course?._id}
                    className="user-course"
                    onClick={() => {
                      navigate(`/admin-course/${course?._id}`);
                    }}
                    style={{borderRadius: '20px'}}
                  > 
                    <div className="position-absolute text-white p-2 rounded-pill mt-2 me-2 bg-danger end-0"
                      onClick={(e)=> deleteCourse(e,course._id)}
                    >
                      <DeleteForeverOutlinedIcon/>
                    </div>
                    <img
                      className="w-100"
                      src={`/courseImages/${course?.image}`}
                      alt=""
                      style={{borderTopLeftRadius: '20px' , borderTopRightRadius: '20px'}}
                    />
                    
                    <div className="user-course-content">
                      <h5 className="fs-6">{course?.courseName} Course</h5>
                      <span>Duration: {course?.duration} days</span>
                      <span>Worth: {course?.price}/-</span>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </AdminLayout>
    </AdminHeader>
  );
}

export default Admin;
