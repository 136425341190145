import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import Layout from "./layout/Layout";
import "./ReferralLink.css";
import { useSelector } from "react-redux";

const ReferralLink = () => {
  const { user } = useSelector((state) => state.user);
  const [linkCopyMsg, setLinkCopyMsg] = useState("");
  const [refCodeCopyMsg, setRefCodeCopyMsg] = useState("");

  const handleCopyClick = (value) => {
    const textArea = document.createElement("textarea");
    if (value === "refLink") {
      textArea.value = `https://skillriseup.com/register/${user?.inviteCode}`;
    } else {
      textArea.value = `${user?.inviteCode}`;
    }

    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
      if (value === "refLink") {
        setLinkCopyMsg("Invite Link Copied to Clipboard");
      } else {
        setRefCodeCopyMsg("Invite Code Copied to Clipboard");
      }
    } catch (err) {
      console.error("Unable to copy:", err);
    } finally {
      document.body.removeChild(textArea);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLinkCopyMsg("");
      setRefCodeCopyMsg("");
    }, 2000);
  }, [linkCopyMsg, refCodeCopyMsg]);
  return (
    <Header>
      <Layout>
        <div className="welcome-container">
          <h5>My Referral</h5>
          <hr />
          <div className="username">
            <span>
              The richest people in the world build networks, everyone else is
              trained to look for works - "Robert Kiyosaki"
            </span>
          </div>
        </div>
        <div className="refer-link my-4">
          <label htmlFor=" " className="fw-bold">
            Referral Link
          </label>
          <input
            type="text"
            className="form-control"
            value={`https://skillriseup.com/register/${user?.inviteCode}`}
          />
          <div className="d-flex flex-column">
            <span
              className={`border border-dotted px-2 py-2 text-primary mt-3 text-start ${
                linkCopyMsg === "" ? "d-none" : "block"
              }`}
              style={{ fontSize: "13px" }}
            >
              {linkCopyMsg}
            </span>
            <button
              className="shadow  border border-dark ref-btn"
              onClick={() => {
                handleCopyClick("refLink");
              }}
            >
              Copy Ref Link
            </button>
          </div>
        </div>
        <div className="refer-code">
          <label htmlFor="" className="fw-bold">
            Referral Code
          </label>
          <input
            type="text"
            className="form-control"
            value={user?.inviteCode}
          />
          <div className="d-flex flex-column">
            <span
              className={`border px-2 py-2 text-primary mt-3 text-start ${
                refCodeCopyMsg === "" ? "d-none" : "block"
              }`}
              style={{ fontSize: "13px" }}
            >
              {refCodeCopyMsg}
            </span>
            <button
              className="shadow ref-btn border border-dark"
              onClick={() => {
                handleCopyClick("refCode");
              }}
            >
              Copy Ref Code
            </button>
          </div>
        </div>
      </Layout>
    </Header>
  );
};

export default ReferralLink;
