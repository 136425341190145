import React from "react";
import { Link } from "react-router-dom";
import "../Footer/Footer.css";
import IMAGES from "../../img";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const Footer = () => {
  return (
    <div className="container-fluid text-white footer-container">
      <div className="row">
        <div className="mb-4 col-12 col-sm-12 col-md-4 col-lg-4">
          <div className="footer-logo">
            <span className="text-waring">
              {/* <GroupWorkIcon className="GroupWorkIcon me-2" /> */}
              <div className="logo mb-3">
                <span className="text-waring cursor-pointer">
                  <h2>
                    <span className="fs-2">
                    <img width="200px" src={IMAGES.edyguru} alt="" /> 

                    </span>
                    <div className="hr-line"></div>
                  </h2>
                </span>
              </div>
            </span>
          </div>
          <div className="footer-para">
            <span>
              Skill rise up is bringing the learner community of all age groups
              under one roof to learn, implement, and grow with the dynamic
              skills of the future to help you stand out of the crowd.
            </span>
          </div>
          <div className="social-icons d-flex mb-4">
  

         
          </div>
        </div>
        <div className="mb-4 col-6 col-sm-6 col-md-3 col-lg-3">
          <h5 className="line">Support</h5>
          <div className="support-links d-flex flex-column">
            {/* <Link to="/contact">Contact</Link> */}
            <Link to="/disclaimer">Disclaimer</Link>
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
            <Link to="/refund-policy">Refund Policy</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </div>
        <div className="mb-4 col-6 col-sm-6 col-md-3 col-lg-3">
          <h5 className="line">Useful Links</h5>
          <div className="support-links d-flex flex-column">
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/contact">Contact</Link>
            {/* <Link to="/service">Services</Link> */}
            <Link to="/register">Became an Affiliate</Link>
          </div>
        </div>
        <div className="mb-4 col-12 col-sm-12 col-md-2 col-lg-2">
          <h5 className="line">Partnership</h5>
          <div className="support-links d-flex flex-column">
            <Link>Become an investor (coming soon)</Link>
          </div>
        </div>
      </div>
      <hr style={{ color: "#fff" }} />
      <div className="copyright text-center">
        <span>
          <small>
            All Rights Reserved @2024 SKill Rise Up
          </small>
        </span>
      </div>
    </div>
  );
};

export default Footer;
