import React from "react";
import Header from "../components/Header/Header";
import Faq from "../components/Home/Faq";

const FaqPage = () => {
  return (
    <Header>
      <Faq />
    </Header>
  );
};

export default FaqPage;
