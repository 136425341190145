import React from "react";
import "./AdminSideMenu.css";

const AdminBackdrop = ({ adminSideMenu, setAdminSideMenu }) => {
  return (
    <React.Fragment>
      <div
        className={`admin-backdrop ${adminSideMenu ? "adminbactive" : ""}`}
        onClick={() => setAdminSideMenu(!adminSideMenu)}
      ></div>
    </React.Fragment>
  );
};

export default AdminBackdrop;
