import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header/Header";
import Layout from "./layout/Layout";
import SummarizeIcon from "@mui/icons-material/Summarize";
import WarningIcon from "@mui/icons-material/Warning";
import LoopIcon from "@mui/icons-material/Loop";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import VerifiedIcon from "@mui/icons-material/Verified";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import "./Wallet.css";
import IMAGES from "../img";
import { useDispatch, useSelector } from "react-redux";
import { SetUser } from "../redux/features/userSlice";
import axios from "axios";
import { useParams } from "react-router-dom";
import WithdrawHistory from "./components/WithdrawHistory";
import WithdrawAmountModal from "./WithdrawAmountModal";

const Wallet = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const addCoinModel = useRef();
  const { user } = useSelector((state) => state.user);
  const [alert, setAlert] = useState(true);
  const [alert2, setAlert2] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [state, setState] = useState(false);
  const [loading, setLoading] = useState(false);

  const [totalProgressCoin, setTotalProgressCoin] = useState(0);
  const [totalSuccessCoin, setTotalSuccessCoin] = useState(0);
  const [tab, setTab] = useState(0);
  const paramsValue = params?.value?.split("_");

  const generateTransactionId = async () => {
    const timestamp = Date.now();
    const random = Math.floor(Math.random() * 10000);
    const transactionId = `${timestamp}${random}`;
    setTransactionId(transactionId);
    return transactionId;
  };

  const setWalletValue = () => {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        id: user?._id,
        wallet: !user?.wallet,
      },
      withCredentials: true,
      url: `api/user/set-wallet`,
    })
      .then((res) => {
        if (res.data.success) {
          setTimeout(() => {
            setLoading(false);
            dispatch(SetUser(res.data.data));
          }, 1500);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getAllWithDrawals = () => {
    axios({
      method: "POST",
      data: {
        id: user?._id,
      },
      withCredentials: true,
      url: `api/withdraw/get-all-withdrawals`,
    })
      .then((res) => {
        if (res.data.success) {
          setTimeout(() => {
            const data = res.data.data;
            const progCoin = data.reduce((acc, item) => {
              if (item.status === "1") {
                return acc + parseInt(item.amount, 10);
              }
              return acc;
            }, 0);
            console.log(progCoin);
            // const getProgressData = (elm) => {
            //   let totalProgress = 0;
            //   elm?.filter((data) => {
            //     if (data?.status === "2") {
            //       const amountNumber = parseInt(data?.amount, 10);
            //       return (totalProgress += amountNumber);
            //     }
            //   });
            //   return totalProgress;
            // };
            const getSuccessData = (elm) => {
              let totalSuccess = 0;
              elm?.filter((data) => {
                if (data?.status === "3") {
                  const amountNumber = parseInt(data?.amount, 10);
                  return (totalSuccess += amountNumber);
                }
              });
              return totalSuccess;
            };
            setTotalProgressCoin(progCoin);
            setTotalSuccessCoin(getSuccessData(data));
          }, 1500);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllWithDrawals();
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (state === false) {
      generateTransactionId();
    }
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (
      params?.value?.split("_")[0] === "success" ||
      params?.value?.split("_")[0] === "failure"
    ) {
      // paymentAlertModal?.current?.click();
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    } else {
      if (params.value === undefined) {
      } else {
        addCoinModel?.current?.click();
      }
    }
    // eslint-disable-next-line
  }, [params?.value]);

  return (
    <Header>
      <Layout>
        {/* Redeem  */}
        <div className="redeem">
          <h5>Redeem Comission</h5>
          <div className="toggle" onClick={setWalletValue}>
            <span className={`circle ${user?.wallet ? "activ" : ""}`}></span>
          </div>
        </div>
        <hr className="my-2 mb-4" />

        {/* wallet add coin payment alert msg */}
        {(params?.value?.split("_")[0] === "success" ||
          params?.value?.split("_")[0] === "failure") && (
          <p>
            <span>
              {paramsValue[0] === "success" && alert === true && (
                <span className="px-2 py-1 d-flex flex-column justify-content-center align-items-center">
                  <CheckBoxIcon className="fs-1 text-success" />
                  <span className="text-success" style={{ fontSize: "14px" }}>
                    {Math.round(paramsValue[1])} Coin Added Successfully
                  </span>
                </span>
              )}
              {paramsValue[0] === "failure" && alert === true && (
                <span className="px-2 py-1 d-flex flex-column justify-content-center align-items-center">
                  <WarningIcon className="text-danger fs-1" />{" "}
                  <span className="text-danger" style={{ fontSize: "14px" }}>
                    Failed to Add {Math.round(paramsValue[1])} Coin
                  </span>
                </span>
              )}
            </span>
          </p>
        )}

        {!loading ? (
          <div>
            {user?.wallet && (
              <React.Fragment>
                {/* Comission  */}
                <div className={`comission`}>
                  {user?.amount > 0 ? (
                    <h5 className="m-0 d-flex justify-content-center align-items-center">
                      <VerifiedIcon className="me-2 fs-1 text-primary" />
                      <b>Comission Ready to withdraw</b>
                    </h5>
                  ) : (
                    <h5 className="m-0 d-flex justify-content-center align-items-center">
                      <NotificationImportantIcon className="me-2 fs-1 text-white bg-danger rounded-pill p-1" />
                      <p className="m-0">
                        Sorry, currently you have zero balance. Start referring
                        people to earn money
                      </p>
                    </h5>
                  )}
                  <span>
                    {user?.amount >= 500 && (
                      <ArrowCircleRightIcon
                        className={`fs-1 ${
                          user?.amount >= 500 ? "cursor-pointer" : ""
                        }`}
                        data-bs-toggle="modal"
                        data-bs-target="#gergrevrg54rhheerh"
                      />
                    )}
                  </span>
                </div>

                {/* withdraw coin modal */}
                <div
                  className="modal fade"
                  id="gergrevrg54rhheerh"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <WithdrawAmountModal
                    user={user}
                    setState={setState}
                    setAlert2={setAlert2}
                    alert2={alert2}
                  />
                </div>

                {/* Wallet Balance  */}
                <div className="wallet-balance-container">
                  <div
                    className="available-balance"
                    ref={addCoinModel}
                    data-bs-toggle="modal"
                    data-bs-target="#gergrevrg54rhheerh"
                    onClick={() => {
                      setState(true);
                    }}
                  >
                    <div className="name px-4">
                      <SummarizeIcon className="icon" />
                      <span>Available</span>
                    </div>
                    <div className="amount px-4">
                      <span className="text-muted">
                        <b>Amount</b>
                      </span>
                      <b>{user?.amount}</b>
                    </div>
                    <div className="wallet-button">
                      <button>Withdraw Money</button>
                    </div>
                  </div>
                  <div className="available-balance">
                    <div className="name px-4">
                      <LoopIcon className="icon" />
                      <span>In Progress</span>
                    </div>
                    <div className="amount px-4">
                      <span className="text-muted">
                        <b>Amount</b>
                      </span>
                      <b>{totalProgressCoin}</b>
                    </div>
                    <div
                      className="wallet-button"
                      onClick={() => {
                        setTab(1);
                      }}
                    >
                      <button>View Status</button>
                    </div>
                  </div>
                  <div className="available-balance">
                    <div className="name px-4">
                      <ThumbUpIcon className="icon" />
                      <span>Paid</span>
                    </div>
                    <div className="amount px-4">
                      <span className="text-muted">
                        <b>Amount</b>
                      </span>
                      <b>{totalSuccessCoin}</b>
                    </div>
                    <div
                      className="wallet-button"
                      onClick={() => {
                        setTab(2);
                      }}
                    >
                      <button>Check History</button>
                    </div>
                  </div>
                </div>
                <div>
                  {tab === 1 && (
                    <div>
                      <WithdrawHistory
                        heading={"Withdrawal Status"}
                        img={IMAGES?.coin}
                        value="1"
                        id={user?._id}
                      />
                    </div>
                  )}
                  {tab === 2 && (
                    <div>
                      <WithdrawHistory
                        heading={"All Paid History"}
                        img={IMAGES?.coin}
                        value="2"
                        id={user?._id}
                      />
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
            {!user?.wallet && (
              <React.Fragment>
                <div className="not-active-wallet">
                  <h6>Switch on the Wallet and Get the Following Benefits</h6>
                  <ul>
                    <li>
                      <small>
                        Instant withdrawal within seconds of request.
                      </small>
                    </li>
                    <li>
                      <small>OTP enabled secure transactions.</small>
                    </li>
                    <li>
                      <small>
                        No need to contact support for commission transfer.
                      </small>
                    </li>
                    <li>
                      <small>24/7 commission withdrawal available.</small>
                    </li>
                    <li>
                      <small>
                        User-friendly interface for easy management and
                        withdrawals.
                      </small>
                    </li>
                    <li>
                      <small>No minimum withdrawal amount.</small>
                    </li>
                    <li>
                      <small>
                        Complete transparency in transaction details
                      </small>
                    </li>
                  </ul>
                </div>
              </React.Fragment>
            )}
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <span
              className="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="ms-2">Loading...</span>
          </div>
        )}
      </Layout>
    </Header>
  );
};

export default Wallet;
