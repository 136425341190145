import React from "react";
import IMAGES from "../../img";
import { useNavigate } from "react-router-dom";
import "./FingerTips.css";

const FingerTips = () => {
  const navigate = useNavigate();
  return (
    <div className="finger-tips-container parent-container">
      <div className="left-container">
        <h2>
          <span>Courses</span> at your <br />
          fingertips
        </h2>
        <div className="my-2 mt-4 text-muted d-flex justify-content-center align-items-center">
          <span className="left-para-circle"></span>
          <span className="left-container-para">
            Enjoy premiun courses after registration
          </span>
        </div>
        <div className="my-2 text-muted d-flex justify-content-center align-items-center">
          <span className="left-para-circle"></span>
          <span className="left-container-para">
            Master new skills with our well designed courses
          </span>
        </div>
        <div className="my-2 mb-5 text-muted d-flex justify-content-center align-items-center">
          <span className="left-para-circle"></span>
          <span className="left-container-para">
            You can also invite other students and can earn money
          </span>
        </div>
        <button
          className="hero-btn-enroll mb-4"
          onClick={() => {
            navigate("/register");
          }}
        >
          Enroll Now
        </button>
      </div>
      <div className="right-container">
        <img src={IMAGES.finger} alt="" />
      </div>
    </div>
  );
};

export default FingerTips;
